//recalculates price based on selected currency
function CalculatePrice(price, defCurr, curr){
    if(typeof defCurr === 'undefined' || typeof curr === 'undefined')
    {
      console.log('error calculating price');
    }

    if(defCurr === curr)
      return price;

    if(curr.course !== 1 && defCurr.course === 1){
      price *= curr.course;
    }else if(curr.course === 1 && defCurr.course !== 1){
      price /= defCurr.course;
    }else if(curr.course !== 1 && defCurr.course !== 1){
      price = price *curr.course / defCurr.course;
    }
    return price;
}

function GetResortDiscount(resort){
   return resort === 'hostel' ? 0.85 : 1;
}

function GetQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  return null;
}

function GetDiscount(discounts, duration, propertyType){
  const resortDiscount = 1 - GetResortDiscount(propertyType);
  let discount = 0;
  if(!discounts || duration < 12)
    discount = 0;
  else if(duration >= 12 && duration < 24)
    discount = discounts.discount1 + resortDiscount;
  else 
    discount = discounts.discount2 + resortDiscount;

  return {
    multiplier: 1 - discount,
    txt: (discount * 100).toFixed(2) + '%'
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export { CalculatePrice, GetResortDiscount, GetQueryVariable, GetDiscount, capitalizeFirstLetter };