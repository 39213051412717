import React, { useState, useEffect } from "react";
import '../../config/i18n';
import axios from "axios";

import "./Languages.css";

import Language from "./Language/Language";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../config/i18n";
import { setAppLanguage } from "../../store/actions/actionsCreators";
import Cookies from "universal-cookie";

const Languages = () => {
  const dispatch = useDispatch();
  const globalLanguage = useSelector(state => state.appLanguage);
  const [visibility, setVisibility] = useState(false);
  const [data, setData] = useState(null);

  // Change the app language
  const changeTranslationHandle = (name) => {
    setVisibility(false);
    dispatch(setAppLanguage(name));
  };

  useEffect(() => {
    if(!globalLanguage){
      return;
    }
    
    const cookies = new Cookies();
    cookies.set('kw-app-lang', globalLanguage);
  }, [globalLanguage]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get('locales/languages');
      setData(response.data);
    }
    fetchData();
  }, []);

  const visibilityClass = visibility ? "visible" : "hidden";

  return (
    <div className="language-container">
      <div className="language-selector" onClick={() => setVisibility(!visibility)}>
        <Language country={globalLanguage} />
      </div>
      <div className={visibilityClass}>
        {data
          ? data.map(l =>
            <Language
              key={l}
              country={l}
              click={(name) => changeTranslationHandle(name)}
            />
          )
          : null
        }
      </div>
    </div>
  );
}

export default Languages;
