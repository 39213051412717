import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import './config/i18n';

import { Provider } from 'react-redux';
import store from '../src/store/store';

/* -------------- */
/*     STYLES     */
/* -------------- */

/* Semantic UI */
import 'semantic-ui/dist/semantic.min.css';

/* Local */
import './index.css';



/* ------------------ */
/*     JAVASCRIPT     */
/* ------------------ */

/* Semantic UI */
// import 'semantic-ui/dist/semantic.min.js';

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
