import React from "react";

import "./Language.css";

 const Language = ({click, country}) => {
    return (
      click ? 
        <div 
          className={"wow fadeInLeft flag icon-" + country} 
          onClick={ () => click(country) }
        >
        </div>
        :<div className={"wow fadeInLeft flag icon-" + country}></div>
    );
}


export default Language;