import { SET_PROPERTY, SET_ROOMS, SET_VERSION, SET_SOFTWARE, SET_ORDER_INFO, SET_DURATION, SET_CURRENCY } from "../actions/constants";

export const orderInfo = (state = {}, action) => {
    switch (action.type) {
        case SET_ORDER_INFO: {
            const data = action.payload;
            const newState = { ...state, ...data };
            return newState;
        }
        case SET_SOFTWARE: {
            const intValue = parseInt(action.payload);
            if(intValue){
                return {
                    ...state,
                    softwareVersion: intValue
                }
            }
            return state;
        }
        case SET_PROPERTY: {
            return {
                ...state,
                property: action.payload
            }
        }
        case SET_ROOMS: {
            const intValue = parseInt(action.payload);
            if(intValue){
                return {
                    ...state,
                    roomId: intValue
                }
            }
            return state;
        }
        case SET_VERSION: {
            const intValue = parseInt(action.payload);
            if(intValue){
                return {
                    ...state,
                    programLang: intValue
                }
            }
            return state;
        }
        case SET_DURATION: {
            const intValue = parseInt(action.payload);
            if(intValue){
                return {
                    ...state,
                    duration: intValue
                }
            }
            return state;
        }
        case SET_CURRENCY: {
            const intValue = parseInt(action.payload);
            if(intValue){
                return {
                    ...state,
                    currency: intValue
                }
            }
            return state;
        }
        default: {
            return state;
        }
    }
}