import React, { useEffect } from "react";

const useGoogleTagManager = () => {
    // if(process.env.NODE_ENV === 'development'){
    //     return null;
    // }
    const GetTagId = () => {
        if (window.location.href.includes('shop.kwhotel.com'))
            return 'G-RRKW1PBXDG';
        else if (window.location.href.includes('shop.kwhotel.pl'))
            return 'G-047RHGY6L2';
        else
            return null;
    }

    useEffect(() => {
        const id = GetTagId();

        if(!id){
            return;
        }

        let script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
        script.async = true;
        document.head.appendChild(script);

        script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerText = `window.dataLayer = window.dataLayer || []; function gtag() { window.dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${id}');`
        document.head.appendChild(script);

    }, []);
}

export default useGoogleTagManager;