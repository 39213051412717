import { useSelector } from "react-redux";
import axios from 'axios'

export const useOrderPayment = () => {
    const { discount } = useSelector(state => state);

    const makePayment = async (orderId, paymentForm) => {
        const response = await axios.put(`shop/order/${orderId}/payment`, {}, { params: { paymentForm: paymentForm, discountCode: discount.code } })
       
        if(response.status === 200){
            return [true, response.data];
        }else{
            return [false, null];
        }
    }

    return makePayment;
}