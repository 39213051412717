import React from "react";
import { withTranslation } from "react-i18next";

import "./Resort.css";

const Property = ({ t, name, selectedInput, changeRadioValue, property }) => {
  const isSelectedInput =  name === selectedInput;

  return (
    <label className="checked">
      <div id={"resort-" + name} className={"buildings " + (isSelectedInput ? 'input-selected' : '')}>
        <input type="radio" name="bussiness" value={name}
          checked={property === name}
          onClick={changeRadioValue}
        />
        <div className={"icon " + name} />
        <p>{t('PAGE1_OBJECT_' + name.toUpperCase())}</p>
      </div>
    </label>
  );
}

export default withTranslation()(Property)
