import React, { useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";

import DurationSlider from "../../GeneralComponents/RangeSlider/RangeSlider";
import "../CartSummary/CartSummary.css";

import "./CurrencySelector.js";
import CurrencySelector from "./CurrencySelector.js";

import * as utility from "../../../containers/Utility";
import { useSelector } from "react-redux";
import DiscountCode from "../../GeneralComponents/DiscountCode/DiscountCode";

const CartSummary = ({ t, productsHandle, orderId = 0 }) => {
  const queryUserId = utility.GetQueryVariable("userId");
  const [userId] = useState(queryUserId ? queryUserId : '');
  const [prices, setPrices] = useState(null);

  const discount = useSelector(state => state.discount);
  const orderInfo = useSelector(state => state.orderInfo);
  const { partner, discounts } = useSelector(state => state.partner);
  const products = useSelector(state => state.products);
  const { currencies } = useSelector(state => state.configuration);
  const { dealerId } = useSelector(state => state.dealerId);

  const discountCode = discount && discount.code ? discount.code : "";
 

  useEffect(() => {
    if (!orderInfo || !partner.id) {
      return;
    }

    if (!orderInfo.softwareVersion
      || !orderInfo.property
      || !orderInfo.roomId
      || !orderInfo.duration) {
      return;
    }

    const partnerId = dealerId ? dealerId : partner.id;
    const { currency, duration, softwareVersion, property, programLang, roomId } = orderInfo;
    const url = `shop/price/${softwareVersion}/${property}/${programLang}/${roomId}?userId=${userId}&currencyId=${currency}&partnerId=${partnerId}&discountCode=${discountCode}&duration=${duration}`;

    axios.post(url, products)
      .then(response => {
        setPrices(response.data);
      });
  }, [orderInfo, discount, products, dealerId, partner]);

  if (!prices || !currencies) {
    return null;
  }

  let currency = currencies.find((p) => p.id === orderInfo.currency);
  currency = currency ? currency : {};

  return (
    <div className="range-slider ui form">
      <div className="col-12 col-lg-6 m-auto">
        {/* Currency */}

        <CurrencySelector />
        {/* Range slider */}
        <div className="row pt-3  wow fadeIn">
          <div className="col-sm-12 col-md-5">
            <p className="text pt-3">{t("PAGE3_TIME")}</p>
            <span className="text-green">
              {orderInfo.duration} {t("PAGE3_TIME_UNIT")}
            </span>
          </div>
          <div className="col-sm-12 col-md-6">
            <DurationSlider
              discount1={discounts.discount1}
              discount2={discounts.discount2}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 m-auto">
        <DiscountCode productsHandle={productsHandle} />
      </div>
      <div className="container-fluid">
        <div className="row modules-footer-wrapper">
          <div className="payment-summary">
            <div>
              <h3 className="sub-text">
                {t("PAGE1_PRICE_SUB")}
                {" " + parseFloat(prices.subscriptionPriceDiscounted * orderInfo.duration).toFixed(2)}{" "}
                {currency.symbol}
              </h3>
            </div>
            <div className="one-time-text">
              {orderInfo.property === "hostel" ? (
                <div className="kw-blue">
                  {t("PAGE1_PRICE_HOSTEL_DISCOUNT")}
                </div>
              ) : null}
            </div>
            <p className="one-time-text">
              {t("PAGE1_PRICE_ONETIME")}
              <strong className="txt-strong one-time-text">
                {" " + (prices.oneTimePriceDiscounted).toFixed(2)}{" "}
                {currency.symbol}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(CartSummary);
