import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppLanguage, setConfiguration, setContact, setDealer, setOrderInfo, setPartner, setVersion, setLinks } from "../store/actions/actionsCreators";
import * as utility from '../containers/Utility';
import Cookies from "universal-cookie";

import axios from '../config/axios';

export const useAppConfiguration = () => {
    const { configuration, dealerId, appLanguage, orderInfo } = useSelector(state => state);
    const dispatch = useDispatch();

    const loadConfiguration = () => {
        axios
            .get("assortment/configuration")
            .then((response) => {
                dispatch(setConfiguration(response.data));
            });
    }

    useEffect(() => {
        if (configuration.loaded) {
            const url = window.location.href.indexOf('shop.kwhotel.com');

            const defaultProgramLanguage = url > -1 ? 2 : 3;
            const defaultPartnerId = url > -1 ? 2 : 1;
            const defaultAppLanguage = url > -1 ? "en" : "pl";
            const defCurrency = url > -1 ? 2 : 4;

            const initialOrderInfo = {
                softwareVersion: "",
                roomId: "",
                property: "",
                currency: defCurrency, // PLN by default,
                defaultCurr: defCurrency,
                programLang: defaultProgramLanguage,
                duration: 12,
            };

            if (!orderInfo.preloaded) {
                dispatch(setOrderInfo(initialOrderInfo));
            }

            setLanguage(defaultAppLanguage);
            setAppPartner(defaultPartnerId);
            loadPartnerData(defaultPartnerId);
            loadUserInformation();
        }

    }, [configuration]);

    useEffect(() => {
        if (dealerId > 0) {
            loadPartnerLinks(dealerId, appLanguage);
        }
    }, [dealerId, appLanguage]);

    const loadPartnerLinks = (dealerId, appLanguage) => {
        let url = `locales/links?partnerId=${dealerId}&lang=${appLanguage}`;
        axios.get(url).then((response) => {
            dispatch(setLinks(response.data));
        });
    }

    const loadPartnerData = (partnerId) => {
        const url = `shop/partner?partnerId=${partnerId}`;
        axios.get(url).then((response) => {
            if (response.data) {
                dispatch(setPartner(response.data));
            }
        });
    };

    const setAppPartner = (defaultPartnerId) => {
        const cookies = new Cookies();
        
        const queryPartnerId = utility.GetQueryVariable("partnerId");
        const queryPartner = utility.GetQueryVariable("partner");
        const cookiesPartner = cookies.get("partner");

        console.log('window: ', window.location);

        if (queryPartner) {
            dispatch(setDealer(queryPartner, defaultPartnerId));
        }
        else if (queryPartnerId) {
            dispatch(setDealer(queryPartnerId, defaultPartnerId));
        }
        else if (cookiesPartner && window.location.pathname !== '/') {
            //only load from cookies if page is reloaded on summary or customer_data
            dispatch(setDealer(cookiesPartner));
        }
        else {
            dispatch(setDealer(defaultPartnerId));
        }
    }

    const setLanguage = (defaultAppLanguage) => {
        const cookies = new Cookies();
        const appLangCookie = cookies.get('kw-app-lang');
        let language = utility.GetQueryVariable("lang");

        //set language from cookie
        if(appLangCookie && !language){
            language = appLangCookie;
        }

        //set default
        if (!language) {
            language = defaultAppLanguage;
        }

        const appLanguage = configuration.languages.find(
            (l) => l.description === language.toUpperCase()
        );

        language = language.toLowerCase();

        if (!orderInfo.preloaded) {
            dispatch(setOrderInfo({
                programLang: appLanguage.id,
                currency: appLanguage.currencyId,
                defaultCurr: appLanguage.currencyId,
            }));
        }

        dispatch(setAppLanguage(language));
    };

    const loadUserInformation = () => {
        const userId = utility.GetQueryVariable("userId");

        if (!userId) {
            return;
        }

        axios.get("shop/user/" + userId).then((response) => {
            if (!response.data.user || response.data.language) {
                return;
            }

            const customer = {
                contactName: response.data.user.name.split(" ")[0],
                contactSurname: response.data.user.name.split(" ")[1],
                contactEmail: response.data.user.email,
                contactNumber: response.data.user.phoneNumber,
            }

            dispatch(setVersion(response.data.language));
            dispatch(setAppLanguage(response.data.language));
            dispatch(setContact(customer));
        });
    };

    return loadConfiguration;
}