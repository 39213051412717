import { SET_PAYMENT_FORM } from "../actions/constants";

const paymentForms = [
    'bank',
    'espago',
    'payu',
    'tpay',
    'paypal'
];

export const paymentForm = (state = '', action) => {
    switch (action.type) {
        case SET_PAYMENT_FORM: {
            if (paymentForms.includes(action.payload))
                return action.payload;
            return state;
        }
        default: return state;
    }
}