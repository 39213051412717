/* eslint-disable */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

/* Components */
import Footer from "../components/GeneralComponents/Footer/Footer";
import Cart from "../components/Cart/Cart";
import CustomerData from "../components/CustomerData/CustomerData";
import Sumary from "../components/Sumary/Sumary";
import "./General.css";
import ScrollToTop from "../components/GeneralComponents/ScrollToTop/ScrollToTop.js";
import Languages from "../components/Languages/Languages";
import TopBar from "../components/GeneralComponents/TopBar/TopBar";
import BitrixChat from "../components/GeneralComponents/Bitrix/BitrixChat.js";
import LanguagesParser from "../components/GeneralComponents/LanguagesParser/LanguagesParser.js";
import { useSelector } from 'react-redux';

import { useAppConfiguration } from '../custom-hooks/useAppConfiguration';

//import WOW from "/node_modules/wowjs";
import { useActionLogger } from "../custom-hooks/useActionLogger";
import { useAppCache } from "../custom-hooks/useAppCache";
import GoogleTagManager from "../custom-hooks/useGoogleTagManager";
import useGoogleTagManager from "../custom-hooks/useGoogleTagManager";

const App = () => {
  const [pageControl, setPageControl] = useState(process.env.NODE_ENV === "development");
  const logAction = useActionLogger();
  const loadConfiguration = useAppConfiguration();
  const { configuration, orderInfo, partner, dealerId } = useSelector(state => state);

  useGoogleTagManager();
  useAppCache();

  useEffect(() => {
    if (orderInfo.programLang > 0) {
      logAction(0, orderInfo.programLang);
    }
  }, [orderInfo.programLang]);

  useEffect(() => {
    loadConfiguration();
    
  }, []);

  useEffect(() => {
    console.log('partner: ', partner, dealerId)
  }, [partner, dealerId])

  const language = configuration.languages
    ? configuration.languages.find(
      (l) => l.id === orderInfo.programLang
    )
    : null;

  return (
    <div id="app">
      <div id="wrapper">
        <TopBar />
        <LanguagesParser language={language} partnerId={"dealerId"} loadBackup={() => this.loadLanguageBackup()} />
        <Languages />
        <BrowserRouter>
          <ScrollToTop>
          <Switch>
          <Route
              exact
              path="/"
              render={() => (
                <Cart
                  pageControl={pageControl}
                  discounts={[]}
                  dealerId={'dealerId'}
                  discount={{}}
                  discountProducts={[]}
                />
              )}
            />
            <Route
              exact
              path="/customer_data"
              render={() => <CustomerData />}
            />
            <Route
              exact
              path="/summary"
              render={() => <Sumary />}
            />
          </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
      <BitrixChat />
      <Footer />
    </div>
  );
}

export default App;
