import { configureStore } from '@reduxjs/toolkit';
import { configuration } from './reducers/configurationReducer';
import { orderInfo } from './reducers/orderInfoReducer';
import { appLanguage } from './reducers/appLanguageReducer';
import { customer } from './reducers/customerReducer';
import { partner } from './reducers/partnerReducer';
import { dealerId } from './reducers/dealerReducer';
import { links } from './reducers/partnerLinksReducer';
import { products } from './reducers/productsReducer';
import { discount } from './reducers/discountReducer';
import { paymentForm } from './reducers/paymentFormReducer';
import { agreements } from './reducers/agreementsReducer';

export default configureStore({
  reducer: {
    configuration,
    orderInfo,
    appLanguage,
    customer,
    partner,
    dealerId,
    links,
    products,
    discount,
    paymentForm,
    agreements
  },
})