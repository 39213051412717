import { SET_AGREEMENTS } from "../actions/constants"

const initialState = [
    0, //rodo
    0, // terms of sale
    0, // privacy policy + rodo
    0, // email marketing
    0,  // phone marketing
    0 // entrusting data policy
]

export const agreements = (state = initialState, action) => {
    switch (action.type) {
        case SET_AGREEMENTS: {
            if(!Array.isArray(action.payload))
                return state;
            const data = action.payload.slice(0, 6);
            return data;
        }
        default:
            return state;
    }
}