/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

/* styles */
import "./CustomerData.css";

/* Components */
import Button from "../GeneralComponents/Button/Button";
import ContactInformation from "./ContactInformation/ContactInformation";
import CompanyInformation from "./ContactInformation/CompanyInformation";
import HotelInformation from "./ContactInformation/HotelInformation";
import { capitalizeFirstLetter } from "../../containers/Utility";
import { useActionLogger } from "../../custom-hooks/useActionLogger";
import { useSelector } from "react-redux";


const CustomerData = ({ t, pageControl = false }) => {
  const [redirect, setRedirect] = useState("");
  const [formValid, setFormValid] = useState(false);
  const logAction = useActionLogger();
  const { programLang } = useSelector(state => state.orderInfo);
  const { country } = useSelector(state => state.customer.company);

  const validateInputs = () =>{
    checkInputValidity('contactName', document.getElementById('contactName'));
    checkInputValidity('contactSurname', document.getElementById('contactSurname'));
    checkInputValidity('contactEmail', document.getElementById('contactEmail'));
    checkInputValidity('contactPhoneNumber', document.getElementById('contactPhoneNumber'));
    checkInputValidity('companyName', document.getElementById('companyName'));
    checkInputValidity('companyAddress', document.getElementById('companyAddress'));
    checkInputValidity('companyCity', document.getElementById('companyCity'));
    checkInputValidity('companyPostCode', document.getElementById('companyPostCode'));
    checkInputValidity('companyTaxId', document.getElementById('companyTaxId'));
    checkInputValidity('hotelName', document.getElementById('hotelName'));
    checkInputValidity('hotelEmail', document.getElementById('hotelEmail'));
    checkInputValidity('hotelPhone', document.getElementById('hotelPhone'));
    checkInputValidity('hotelWebSite', document.getElementById('hotelWebSite'));
    checkInputValidity('hotelAddress', document.getElementById('hotelAddress'));
    checkInputValidity('hotelCity', document.getElementById('hotelCity'));
    checkInputValidity('hotelPostCode', document.getElementById('hotelPostCode'));
  }

  const checkInputValidity = (name, el) =>{
    controlElementText(name, el.validity.valueMissing, 'Required');
    controlElementText(name, el.validity.tooShort, 'Min');
    controlElementText(name, el.validity.tooLong, 'Max');
    controlElementText(name, el.validity.typeMismatch, 'Type');
  }

  const validateInput = (event, formPart) => {
    const {name} = event.target;
    const realName = formPart + capitalizeFirstLetter(name);
    const el = document.getElementById(realName);
    checkInputValidity(realName, el);

    validationHandle();
    if(el.checkValidity())
    {
      el.classList.remove("text-error");
      return true;
    }else{
      el.classList.add("text-error");
      return false;
    }
  }

  const controlElementText = (elName, value, classSufix) =>{
    const el = document.getElementById(elName + classSufix);
    if(typeof el === 'undefined' || el === null)
      return;
    if(value)
    {
      el.classList.remove('hidden');
    }else{
      el.classList.add('hidden');
    }
  }

  //controls the state of formValid 
  const validationHandle = () => {
    // Agreement acceptance
    const form = document.getElementById('informationForm');
    if(!form)
    {
      return false;
    }

    if(form.checkValidity())
    {
      setFormValid(true);
      return true;
    }
    else{
      setFormValid(false);
      return false;
    }
  };

  if(redirect.length > 0){
    validateInputs();
    const form = document.getElementById('informationForm');
    if(redirect === "/summary" && !form.checkValidity()){
      setRedirect("");
      window.scrollTo(0, 0);
    }
    else {
      if(redirect === "/summary"){
        logAction(3, programLang, country);
      }

      return <Redirect to={redirect}/>
    }
  }

  return (
    <div id="CustomerData" className="container-fluid">
      <form id="informationForm" onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <ContactInformation validateInput={validateInput}/>

          <CompanyInformation validateInput={validateInput}/>

          <HotelInformation validateInput={validateInput} validateInputs={validateInputs}/>
        </div>
      </form>
      <div className="btn-container">
            <Button
              class="btn prev-btn"
              value={t('PAGE2_BTN1')}
              click={() => setRedirect("/")}
            />
            <Button
              class="btn next-btn"
              value={t('PAGE2_BTN2')}
              click={() => setRedirect("/summary")}
            />
          </div>
    </div>
  );
}

export default withTranslation()(CustomerData)