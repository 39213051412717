import React from "react";
// import axios from "axios";
import "./TopBar.css";
import { withTranslation } from "react-i18next";

 const TopBar = ({ t }) => {
    const path = window.location.pathname;
    let step1 = "";
    let step2 = "";
    let step3 = "";

    switch (path) {
      case "/":
        step1 = "active";
        break;

      case "/customer_data":
        step2 = "active";
        break;
      case "/summary_and_payments":
        step3 = "active";
        break;

      default:
        step1 = "active";
    }

    return (
      <div id="topBar">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-3 info-container">
          <p className="stepbar-info">{t('DIALOG_BTN1')}</p>
        </div>

        {/* Installation steps */}
        <div className={"col-12 col-lg-3 col-xl-2 steps " + step1}>
          <p className="steps-text">{t('TOP_BAR2')}</p>
        </div>

        <div className={"col-12 col-lg-3 col-xl-2 steps step2 " + step2}>
          <p className="steps-text">{t('TOP_BAR3')}</p>
        </div>

        <div className={"col-12 col-lg-3 col-xl-2 steps step3 " + step3} >
          <p className="steps-text">{t('TOP_BAR4')}</p>
        </div>

        <div className="col-12 col-lg-3 col-xl-3 time-container d-flex justify-content-center">
            <div className="col-4 col-sm-5 speedometerContainer">
              <div className="speedometer"/>
            </div>
            {step1 === 'active' ?
                <div className="col-8 col-sm-7">
                  <p className="stepbar-info minutes">{t('TOP_BAR5')}</p>
                </div> 
              : null}
            {step2 === 'active' ?
                <div className="col-8 col-sm-7">
                  <p className="stepbar-info minutes">{t('TOP_BAR6')}</p>
                </div> 
              : null}
            {step3 === 'active' ?
                <div className="col-8 col-sm-7">
                  <p className="stepbar-info minutes">{t('TOP_BAR7')}</p>
                </div> 
              : null}
        </div>
      </div>
    );
}

export default withTranslation()(TopBar);