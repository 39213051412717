import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Popup } from "semantic-ui-react";

import "./PaymentOptions.css";

const PaymentOption = ({ index, selectedIndex, clickHandle, commision }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState();
  const imageActive = selectedIndex === index ? "payment-option-selected" : "";
  const commisionDisplay = ((commision % 1) * 100).toFixed(2);

  import(`./payment-options/${index}.png`).then((img) => {
    setImage(img.default);
  });

  if (!image) {
    return null;
  }

  const generateImage = () => {
    return (
      <Image
        key={index}
        className={"payment-option-image " + imageActive}
        src={image}
        size="small"
        onClick={() => clickHandle(index)}
      />
    );
  };

  return (
    <>
      {commision > 1 ? (
        <Popup
          content={t("COMMISION") + ": " + commisionDisplay + "%"}
          trigger={generateImage()}
        />
      ) : (
        generateImage()
      )}
    </>
  );
};

export default PaymentOption;
