/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "./CategoryWrapper.css";
import Dialog from "../Modal/Modal";

import Cookies from "universal-cookie";
/* Components */
import Category from "./Category/Category";
import { useDispatch, useSelector } from "react-redux";
import { setProduct, setProducts } from "../../../store/actions/actionsCreators";

const cookies = new Cookies();

const CategoryWrapper = ({ t, discountProducts = [] }) => {
  const orderInfo = useSelector(state => state.orderInfo);
  const dealerId = useSelector(state => state.dealerId);
  const { currencies } = useSelector(state => state.configuration);
  const [dialogId, setDialogId] = useState(null);
  const [currency, setCurrency] = useState({});
  const [data, setData] = useState(null);
  const [discountExclusions, setDiscountExclusions] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    //if lang - modal is showing we do not load data cause user will either change site or keep old language
    const langModal = document.getElementById('lang-modal');
    if (langModal)
      return;

    if (!data) {
      getCategoryData();

      dispatch(setProduct({ id: 123, providers: [] }));
    }

  }, []);

  useEffect(() => {
    if (orderInfo.softwareVersion === 80)
      dispatch(setProducts([]));
    if (orderInfo.softwareVersion === 110)
      dispatch(setProducts([{ id: 123, providers: [] }]));

  }, [orderInfo.softwareVersion]);

  useEffect(() => {
    if (currencies) {
      const curr = currencies.find(
        (p) => p.id == orderInfo.currency
      );
      setCurrency(curr);
    }

  }, [currencies, orderInfo.currency]);

  useEffect(() => {
    if (dealerId && !discountExclusions) {
      getDiscountExclusions(dealerId);
    }
  }, [dealerId])

  const getDiscountExclusions = (dealerId) => {
    axios.get("discounts/exclusions", { params: { partnerId: dealerId } })
      .then(response => {
        setDiscountExclusions(response.data);
      });
  }

  const getCategoryData = () => {
    axios.get("assortment/categories")
      .then(response => {
        setData(response.data);
      });
  }

  const productHandle = (product, checked) => {
    if (product.d && product.d.enabled && !checked) {
      setDialogId(product.id);
    } else {
      dispatch(setProduct({ id: product.id, providers: [] }));
    }
  }

  const modalHandle = (e, value, providers) => {
    if (!value) {
      document.getElementById(dialogId).checked = false;
      setDialogId(null);
      return;
    }

    dispatch(setProduct({ id: dialogId, providers: providers.map(prov => prov.id) }));
    setDialogId(null);
  }

  if (!data || orderInfo.roomId <= 0) {
    return "";
  }

  const categories = data.map((c) => (
    <Category
      key={c.id}
      id={c.id}
      currency={currency}
      productHandle={productHandle}
      discountProducts={discountProducts}
      discountExclusions={discountExclusions}
    />
  ));

  return (
    <div>
      <Fragment>
        {categories}
        {/* Modal component */}

      </Fragment>
      <Dialog
        click={(e, value, providers) =>
          modalHandle(e, value, providers)
        }
        id={dialogId}
      />
    </div>
  );
}

export default withTranslation(['categories'])(CategoryWrapper);
