import { SET_PRODUCTS, SET_PRODUCT } from "../actions/constants";

export const products = (state = [], action) => {
    switch (action.type) {
        case SET_PRODUCTS: {
                return action.payload;
        }
        case SET_PRODUCT: {
            const existingItem = state.find(item => item.id === action.payload.id);

            if(existingItem){
                const newState = [...state];
                const indexOfItem = newState.indexOf(existingItem);
                newState.splice(indexOfItem, 1);
                return newState;
            }else{
                const newState = [...state, action.payload];
                return newState;
            }
        }
        default: {
            return state;
        }
    }
}