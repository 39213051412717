/* eslint-disable */
import React, { useState } from "react";
/* Styles */
import "./Cart.css";

/* Components */
import PdfBtn from "../Cart/PdfBtn/PdfBtn";
import Button from "../GeneralComponents/Button/Button";
import LanguageVersion from "./Select/LanguageVersion";
import ProgramVersion from "./Select/ProgramVersion";
import Resort from "./Resort/Resort";
import CategoryWrapper from "./CategoryWrapper/CategoryWrapper";
import Rooms from "./Rooms/Rooms";
import CartSummary from "./CartSummary/CartSummary";

import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { useSelector } from "react-redux";
import { useActionLogger } from "../../custom-hooks/useActionLogger";
import { Loader } from "semantic-ui-react";

const Cart = ({ t }) => {
  const [nextStep, setNextStep] = useState(false);
  const orderInfo = useSelector(state => state.orderInfo);
  const [discountProducts, setDiscountProducts] = useState([]);
  const { loaded } = useSelector(state => state.configuration);
  const logAction = useActionLogger();

  const productsHandle = (products) => {
    if (!Array.isArray(products))
      return;

    setDiscountProducts(products);
  }

  if (nextStep) {
    logAction(2, orderInfo.programLang);
    return <Redirect to="/customer_data"></Redirect>;
  }

  let services = (
    <CategoryWrapper
      discountProducts={discountProducts}
    />
  );

  // let individualClass = "pill active";
  const groupClass = "pill active";
  // Unlocks the right component

  const showRightSide = orderInfo.programLang && orderInfo.property
    ? "visible"
    : "hidden-soft";
  // Unlocks the bottom component
  const servicesClass =
    loaded && orderInfo.softwareVersion && orderInfo.roomId
      ? "visible"
      : "hidden";

  const orderInfoClass = loaded ? "visible-scale" : "hidden-scale";

  return (
    <div id="cart">
      {loaded
          ? <></>
          : <Loader />
        }
      <div className={"order-info-wrapper " + orderInfoClass}>
        <div className="order-info-row-wrapper mb-5">
          <LanguageVersion />
          <Resort />
        </div>
        <div className={"order-info-row-wrapper mb-5 " + showRightSide}>
          <ProgramVersion />
          <Rooms />
        </div>
      </div>

      {/* ---------------- */}
      {/*     SERVICES     */}
      {/* ---------------- */}

      <div className={"services-container " + servicesClass}>
        <div className="col-md-4 pills">
          <div className={groupClass}>
            <button
              name="individual"
              value="individual"
            >
              {t("PAGE1_INDIVIDUAL")}
            </button>
          </div>
        </div>

        <div>
          <div className="ui form services-wrapper mr-3 ml-3">
            {services}
          </div>
          <div>
            <CartSummary productsHandle={productsHandle} />
            <div className="btn-container">
              {/*  Next btn */}
              <PdfBtn />
              <Button
                class="next-btn"
                value={t("PAGE1_BTN")}
                click={() => setNextStep(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Cart);
