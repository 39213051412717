import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";

/* Styles */
import "./BillSumary.css";
import DiscountCode from "../../GeneralComponents/DiscountCode/DiscountCode";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency } from "../../../store/actions/actionsCreators";
import { Loader } from "semantic-ui-react";

const SummaryBill = ({ t, orderId = null }) => {
  const dispatch = useDispatch();
  const [finalPrice, setFinalPrice] = useState({ totalGrossPriceDiscounted: 0, totalNetPriceDiscounted: 0 });
  const [orderSummary, setOrderSummary] = useState([]);
  const [tax, setTax] = useState(true);

  const { currencies } = useSelector(state => state.configuration);
  const orderInfo = useSelector(state => state.orderInfo);
  const { partner } = useSelector(state => state.partner);
  const dealerId = useSelector(state => state.dealerId);
  const products = useSelector(state => state.products);
  const discount = useSelector(state => state.discount);
  const { company } = useSelector(state => state.customer);



  const selectedCurrency = currencies
    ? currencies.find(curr => curr.id === orderInfo.currency)
    : null;

  useEffect(() => {
    axios.get('shop/tax', {
      params: {
        taxId: company.taxId,
        country: company.country
      }
    })
      .then(response => {
        setTax(response.data);
      }, response => {
        setTax(true);
      });
  }, [company]);

  useEffect(() => { 
    console.log(1);
    if (!orderId && discount.code && discount.code.length > 0) {
      loadOrderSummary();
    }
  }, [discount]);

  useEffect(() => {
    console.log(2)
    if(!orderId && orderSummary.length === 0){
      loadOrderSummary();
    }
  }, [products, orderInfo, partner]);

  useEffect(() => {
    if (orderId) {
      const url = `shop/order/${orderId}`;
      axios.get(url, {
        params: {
          discountCode: discount.code
        }
      })
        .then(response => {
          dispatch(setCurrency(response.data.currencyId));
          setOrderSummary(response.data.items);
          setFinalPrice(response.data.finalPrice);
        });
    }
  }, [orderId, discount])

  const loadOrderSummary = () => {
    const partnerId = dealerId ? dealerId : partner.id;
    const { softwareVersion, duration, roomId, programLang, property, currency } = orderInfo;
    if (softwareVersion && duration && programLang && roomId && property && partnerId && currency) {
      const modules = products ? products : [];
      const url = `shop/order/${softwareVersion}`;
      axios.post(url, modules, {
        params: {
          duration: duration,
          languageGroupId: programLang,
          roomid: roomId,
          resortType: property,
          partnerId: partnerId,
          currencyId: currency,
          discountCode: discount.code
        }
      })
        .then(response => {
          setOrderSummary(response.data.items);
          setFinalPrice(response.data.finalPrice);
        });
    }
  }

  const generateSummaryRow = (item, index) => {
    const row =
      <tbody key={index}>
        <tr>
          <td>
            {index === null ? "" : index}
          </td>
          <td>
            {t(`products:${item.productId}.Item1`)}
            {item.extraId > 0 && item.extraIdType === 1
              ? ` -  ${t('extra_item_' +item.extraId)}`
              : null}
            {item.extraId > 0 && item.extraIdType === 2
              ? ` - language`
              : null}
            {item.extraId > 0 && item.extraIdType === 3
              ? ` - ` + t('extra_item_upgrade')
              : null}
            {item.extraId > 0 && item.extraIdType === 4
              ? ` - ` + t('extra_item_alignment')
              : null}
          </td>
          <td>{item.duration}</td>
          <td>{item.price.subscriptionPrice.toFixed(2)}</td>
          <td>{item.price.oneTimePrice.toFixed(2)}</td>
          <td>{((1 - item.price.discount) * 100).toFixed(2)}%</td>
          <td>{item.price.totalNetPriceDiscounted.toFixed(2)}</td>
          {
            tax
              ? <td>23%</td>
              : null
          }
          <td>
            {
              tax
                ? item.price.totalGrossPriceDiscounted.toFixed(2)
                : item.price.totalNetPriceDiscounted.toFixed(2)
            }
          </td>
        </tr>
      </tbody>

    return row;
  }

  const orderSummaryArray = orderSummary.map((el, index) => generateSummaryRow(el, index));
  const orderTable = () => {
    return (
      <div className={"col-12 table-responsive bill-sumary wow bounceInUp " + (orderSummary.length === 0 ? "hidden-scale" : "")}>
        <label className="col-12 form-title">
          <i className="fas fa-shopping-cart" /> {t('translation:PAGE3_CART')}
        </label>
        <table className="table table-hover sumary-table data-table">
          <thead>
            <tr>
              <th className="title-header">{t('translation:PAGE3_CART_ENUM')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_PRODUCT')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_PERIOD')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_P_MONTH')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_P_ONCE')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_DISCOUNT')}</th>
              <th className="title-header">{t('translation:PAGE3_CART_P_NET')}</th>
              {
                tax
                  ? <th className="title-header">{t('translation:PAGE3_CART_TAX')}</th>
                  : null
              }
              <th className="title-header">{t('translation:PAGE3_CART_P_GROSS')}</th>
            </tr>
          </thead>
          {orderSummaryArray}
        </table>
        <div className="d-flex justify-content-end">
          <p className="asterisk">
            {t('translation:P3_ASTERISK1')}
          </p>
        </div>
        <div className="d-flex justify-content-start container-fluid">
        </div>
        <div className="ui form justify-content-start container-fluid">
          <DiscountCode />
        </div>
        <div className="d-flex justify-content-end total-container">
          <h1 className="text-price">{t('translation:PAGE3_CART2')}
            <span>
              {
                tax
                  ? finalPrice.totalGrossPriceDiscounted.toFixed(2)
                  : finalPrice.totalNetPriceDiscounted.toFixed(2)
              } {selectedCurrency ? selectedCurrency.symbol : ''}
            </span>
          </h1>
        </div>
      </div>
    );
  }

  return (
    <>
      {orderSummary.length === 0
        ? <Loader />
        : null
      }
      {orderTable()}
    </>
  );
}
export default withTranslation(['translation', 'products'])(SummaryBill);