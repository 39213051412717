import { SET_PARTNER } from "../actions/constants"

const initialState = {
    partner: {},
    discount: {}
}

export const partner = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARTNER: {
            const data = {
                partner: action.payload.partner ? action.payload.partner : state.partner,
                discounts: action.payload.discounts ? action.payload.discounts : state.discount
            }

            return data;
        }
        default: {
            return state
        }
    }
}