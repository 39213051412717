import React from 'react';
import LiveChat from 'react-livechat';

const BitrixChat = () => {
    if(process.env.NODE_ENV === 'development'){
        return null;
    }

    return (
        <LiveChat license={13014651}/>
    );
}

export default BitrixChat;