import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function new_script(src) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
            resolve();
        });
        script.addEventListener('error', function (e) {
            reject(e);
        });
        document.body.appendChild(script);
    })
};
// Promise Interface can ensure load the script only once.
new_script("https://js.espago.com/iframe-1.0.js");
new_script("https://js.espago/com/espago-1.2.js");

const Espago = ({ espagoKey = '', closeHandle }) => {
    const lang = useSelector(state => state.appLanguage);
    const [token, setToken] = useState('');

    useEffect(() => {
        window.my_success_callback = (token) => {
            setToken(token);
            if(closeHandle){
                closeHandle(token);
            }
        };

        window.my_onclose_callback = () => {
            if(closeHandle){
                closeHandle(token);
            }
        }

        window.my_error_callback = () => {
            setToken(null)
        }
        showEspagoFrame();
    }, [espagoKey])

    const showEspagoFrame = () => {
        window.showEspagoFrame();
    }

    return (
        <>
            <form className="form-vertical" onSubmit={(e) => e.preventDefault()} id="testowy-formularz" hidden>
                <div className="form-group"><input className="form-control text-center" id="cc-token" placeholder="..." value={token} onChange={() => console.log('attempting to change espago input')}/></div>
            </form>

            <script id="espagoScriptId" async=""
                data-button="Save card"
                data-id="EspagoFrameScript"
                data-key={espagoKey} data-live="true"
                data-lang={lang}
                data-subtitle="Add your credit card"
                data-target="testowy-formularz"
                data-success="my_success_callback"
                data-error="my_success_callback"
                data-onclose="my_success_callback"
                data-title="Pay with espago" src="https://js.espago.com/iframe-1.0.js">
            </script>
        </>
    )
}


export default Espago;