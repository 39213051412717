import axios from "axios";
import { useSelector } from "react-redux";

const requestHeaders = {
  'content-type': 'application/json'
};

export const useOrderMaker = () => {
  const products = useSelector(state => state.products);
  const orderInfo = useSelector(state => state.orderInfo);
  const agreements = useSelector(state => state.agreements);
  const discount = useSelector(state => state.discount);
  const paymentForm = useSelector(state => state.paymentForm);
  const customer = useSelector(state => state.customer);
  const { partner } = useSelector(state => state.partner);
  const dealerId = useSelector(state => state.dealerId);

  const makeOrder = async (orderId = null) => {
    let url, data;
    if (orderId) {
      url = `/shop/${orderId}/pay`;
      data = preparePayData();
    } else {
      url = '/shop/order';
      data = prepareOrderData();
    }

    const request = axios.post(url, data, {
      headers: requestHeaders
    })
      .then(response => {
          return [true, response.data];
      })
      .catch(response => {
        return [false, response.response.data];
      })

      return request;
  }

  const preparePayData = () => {
    const data = {
      agreements: {
        phone: agreements[4],
        email: agreements[3]
      },
      paymentForm: paymentForm,
      discountCode: discount ? discount.code : ''
    };

    return data;
  }

  const prepareOrderItems = () => {
    let orderItems = products;
    const mainItem = {
      isMainProgram: true,
      id: orderInfo.softwareVersion,
      providers: []
    }

    orderItems.unshift(mainItem);

    return orderItems
  }

  const prepareOrderData = () => {
    const partnerId = dealerId ? dealerId : partner.id;
    const orderItems = prepareOrderItems();
    const data = {
      programs: orderItems,
      company: customer.company,
      agreements: {
        phone: agreements[4],
        email: agreements[3]
      },
      paymentForm: paymentForm,
      discountCode: discount ? discount.code : '',
      customer: {
        ...customer.contact,
        name: customer.contact.name + ' ' + customer.contact.surname,
      },
      hotel: {
        ...customer.hotel,
        type: orderInfo.property
      },
      orderInfo: {
        ...orderInfo,
        partnerId: parseInt(partnerId)
      }
    };

    return data;
  }



  return makeOrder;
};