import { SET_DEALER } from "../actions/constants";
import Cookies from "universal-cookie";

export const dealerId = (state = 0, action) => {
  switch (action.type) {
    case SET_DEALER: {
        const cookies = new Cookies();
        cookies.set('partner', action.payload);

      return action.payload;
    }
    default: {
      return state;
    }
  }
};
