import React, { Fragment } from "react";
import { Popup } from 'semantic-ui-react';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { coppyHotel, setHotel } from "../../../store/actions/actionsCreators";

const HotelInformation = ({ t, validateInput, validateInputs }) => {
  const { countries } = useSelector(state => state.configuration);
  const { hotel } = useSelector(state => state.customer);
  const dispatch = useDispatch();

  const changeHandle = (event) => {
    validateInput(event, 'hotel');
    const { name, value } = event.target;
    const data = {
      ...hotel,
      [name]: value
    };
    dispatch(setHotel(data));
  }

  const copyDataToHotel = () => {
    dispatch(coppyHotel());
  }

  const countryOptions = countries ? countries.map((country) => {
    return (
      <option value={country.iso2} key={country.id}>{country.name}</option>
    );
  }) : [];

  const inputsWithPopups = () => (
    <Fragment>
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelName"
            className=" input-form"
            type="text"
            name="name"
            placeholder={t('PAGE2_HOTEL_NAME')}
            value={hotel.name}
            onChange={changeHandle}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelNameRequired">{t('HOTEL_NAME_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelNameMin">{t('HOTEL_NAME_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_NAME_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelEmail"
            className="input-form"
            type="email"
            name="email"
            placeholder={t('PAGE2_HOTEL_EMAIL')}
            onChange={changeHandle}
            value={hotel.email}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelEmailRequired">{t('HOTEL_EMAIL_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelEmailMin">{t('HOTEL_EMAIL_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_EMAIL_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelPhone"
            className="input-form"
            name="phone"
            type="text"
            placeholder={t('PAGE2_HOTEL_PHONE')}
            onChange={changeHandle}
            value={hotel.phone}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelPhoneRequired">{t('HOTEL_PHONE_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelPhoneMin">{t('HOTEL_PHONE_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_PHONE_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelWebSite"
            className="input-form"
            type="text"
            name="webSite"
            placeholder={t('PAGE2_HOTEL_WEBSITE')}
            onChange={changeHandle}
            value={hotel.webSite}
          />
        </div>}
        content={t('PAGE2_HOTEL_WEBSITE_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelAddress"
            className="input-form"
            type="text"
            name="address"
            placeholder={t('PAGE2_HOTEL_ADDRESS')}
            onChange={changeHandle}
            value={hotel.address}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelAddressRequired">{t('HOTEL_ADDRESS_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelAddressMin">{t('HOTEL_ADDRESS_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_ADDRESS_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelCity"
            className="input-form"
            type="text"
            name="city"
            placeholder={t('PAGE2_HOTEL_CITY')}
            onChange={changeHandle}
            value={hotel.city}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelCityRequired">{t('HOTEL_CITY_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelCityMin">{t('HOTEL_CITY_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_CITY_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="hotelPostCode"
            className="input-form"
            type="text"
            name="postCode"
            placeholder={t('PAGE2_HOTEL_POST')}
            onChange={changeHandle}
            value={hotel.postCode}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="hotelPostCodeRequired">{t('HOTEL_POST_REQUIRED')}</p>
          <p className="error-message hidden" id="hotelPostCodeMin">{t('HOTEL_POST_MIN')}</p>
        </div>}
        content={t('PAGE2_HOTEL_POST_C')}
        on='focus'
        offset='0, 50px'
        position='left center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <select
            id="hotelCountry"
            className="input-form"
            name="country"
            value={hotel.country}
            onChange={changeHandle}>
            {countryOptions}
          </select>
        </div>}
        content={t('PAGE2_HOTEL_COUNTRY_C')}
        on='focus'
        position='left center'
      />
    </Fragment>
  )

  return (
    <div className="ui form col-md-12 col-lg-4 mt-5 p-5 ">
      <label className="form-title mb-5">
        <i className="fas fa-hotel" /> {t('PAGE2_HOTEL_INFORMATION')}
      </label>

      <div className="explanation-container">
        <span>
          <i className="fas fa-info-circle" /> {t('PAGE2_HOTEL_INFORMATION2')}
        </span>
      </div>
      <button type="button" className="btn btn-light" onClick={copyDataToHotel}>
        {t('PAGE2_HOTEL_COPY')}
      </button>
      {inputsWithPopups()}
    </div>
  );
}

export default withTranslation()(HotelInformation)