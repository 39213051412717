import React from "react";
import { Button } from 'semantic-ui-react';

/* Styles */
import "./Button.css";

const CustomButton = props => {
  return (
    <Button
      size='large'
      name={props.name}
      className={'custom-button ' + props.class}
      onClick={props.click}
      disabled={props.disabled}
    >
      {props.value}
    </Button>
  );
};

export default CustomButton;

