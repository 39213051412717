import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency, setOrderInfo } from "../../../store/actions/actionsCreators";

const CurrencySelector = ({ t }) => {
  const { currencies, languages } = useSelector(state => state.configuration);
  const orderInfo = useSelector(state => state.orderInfo);
  const { programLang, currency } = orderInfo;
  const dispatch = useDispatch();

  const displayCurrencies = currencies.map((curr) => (
    <option key={curr.id} value={curr.id}>
      {curr.symbol}
    </option>
  ));

  useEffect(() => {
    if(programLang){
      const language = languages.find(l => l.id === programLang);
      dispatch(setCurrency(language.currencyId));
    }
  }, [programLang]);

  const handleChange = (event) => {
    const value = event.target.value;
    dispatch(setCurrency(value));
  }

  return (
    <div className="row  wow fadeIn">
      <div className="col-12 col-sm-5">
        <p className="text">{t("PAGE3_CURRENCY")}</p>
      </div>
      <div className="col-12 col-sm-7">
        <select
          value={currency}
          onChange={handleChange}
          className="ui-semantic currency-select wow fadeIn"
        >
          {displayCurrencies}
        </select>
      </div>
    </div>
  );
}

export default withTranslation()(CurrencySelector);
