import axios from "axios";

const loadDealerInformation = async (dealerId) => {
  if(!dealerId){
    return null;
  }

  const url = `shop/partner?partnerId=${dealerId}`;
  const { data } = await axios.get(url);
  if (data.partner.isDealer) return data.partner;
  return null;
};

export { loadDealerInformation };
