/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";

/* Components */
import CustomButton from "../GeneralComponents/Button/Button";
import SellerData from "./SellerData/SellerData";
import BillingData from "./BillingData/BillingData";
import SumaryBill from "./BillSumary/BillSumary";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Agreements from '../GeneralComponents/Agreements/Agreements';

import * as utility from "../../containers/Utility";

/* Styles */
import "./Sumary.css";
import PaymentOptions from "../GeneralComponents/PaymentOptions/PaymentOptions";
import { useDispatch, useSelector } from "react-redux";
import { setCompany, setContact, setDealer, setPartner } from "../../store/actions/actionsCreators";
import { useOrderMaker } from "../../custom-hooks/useOrderMaker";
import { useOrderPayment } from "../../custom-hooks/useOrderPayment";
import Espago from "../GeneralComponents/Espago/Espago";
import { Redirect } from "react-router-dom";

const CustomerData = ({ t }) => {
  const [redirect, setRedirect] = useState();
  const [sendingOrder, setSendingOrder] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [orderBtnDisabled, setOrderBtnDisabled] = useState(false);
  const [hideBtns, setHideBtns] = useState(false);
  const [bank, setBank] = useState(null);
  const [espagoKey, setEspagoKey] = useState(null);
  const { partner } = useSelector(state => state.partner);
  const agreements = useSelector(state => state.agreements);
  const paymentForm = useSelector(state => state.paymentForm);
  const customer = useSelector(state => state.customer);
  const orderInfo = useSelector(state => state.orderInfo);

  const [orderId, setOrderId] = useState(utility.GetQueryVariable("order"))
  const dispatch = useDispatch();
  const makeOrder = useOrderMaker();
  const makePayment = useOrderPayment();

  useEffect(() => {
    //prevent redirecting when loading prolongation
    if(orderId){
      return;
    }

    //data missing, redirect to page that 
    if(orderInfo.roomId === "" || orderInfo.property === "" || orderInfo.softwareVersion === ""){
      setRedirect("/");
      return;
    }
    
    if(!customer.default && !customer.contact.email){
      setRedirect("/customer_data");
      return;
    }
  }, [orderInfo, customer])

  useEffect(() => {
    if (agreements[1] && agreements[2] && agreements[5] && paymentForm.length > 0) {
      setOrderBtnDisabled(false);
    } else {
      setOrderBtnDisabled(true);
    }
  }, [agreements, paymentForm]);

  useEffect(() => {
    console.log('order: ', {orderId}, {customer});
    if (orderId) {
      axios.get(`shop/order/${orderId}/information`)
        .then(response => {
          if (response.data.partner && response.data.partner.id !== partner.id) {
            dispatch(setDealer(response.data.partner.id));
          }
          if (response.data.company && response.data.customer) {
            dispatch(setCompany(response.data.company));
            dispatch(setContact(response.data.customer));
          } else {
            setRedirect("/");
          }
        }, response => {
          setRedirect("/");
        })
    }
  }, [orderId]);

  const makeOrderHandle = async () => {
    let result;
    setSendingOrder(true);
    if (!orderId) {
      result = await makeOrder();
    } else {
      result = await makePayment(orderId, paymentForm);
    }

    if (result[0]) {
      setOrderId(orderId)
      setSendingOrder(false);
      setHideBtns(true);
      if (paymentForm === 'bank') {
        setBank(result[1]);
        return;
      }

      if (paymentForm === 'espago') {
        setEspagoKey(result[1]);
        return;
      }

      window.location = result[1];
    } else {
      setOrderError(result[1]);
    }
  }

  const espagoHandle = (token) => {
    axios.post('https://partner.kwhotel.com/payment/espago', {
      orderId: espagoKey.orderId,
      espagoToken: token
    })
      .then(response => {
        if (response.data && response.data.length > 0) {
          window.location = response.data;
        }
      });
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div id="sumary">
      <script src='https://js.espago.com/espago-1.2.js' type='text/javascript'></script>
      <div className="row p-4 p-lg-0 sumary">
        <SellerData />
        <BillingData />
      </div>

      <SumaryBill orderId={orderId} />

      <div className="p-5">
        <PaymentOptions warning={orderBtnDisabled} />
      </div>

      <div>
        <Agreements warning={orderBtnDisabled} />
      </div>

      {hideBtns
        ? null
        : <div className="btn-container">
          {orderId
            ? <div></div>
            : <CustomButton class="prev-btn" name="back" value={t("PAGE3_BTN_BACK")} click={() => setRedirect("/customer_data")} />
            /* <CustomButton class="prev-btn" name="back" value={t("PAGE3_BTN_BACK")} click={() => setRedirect("/customer_data")} /> */
          }
          <CustomButton class="next-btn pay-btn" name="payBtn" value={t('PAGE3_BTN_PAY')} disabled={orderBtnDisabled} click={makeOrderHandle} />
        </div>
      }

      <Modal show={sendingOrder}
        backdrop="static"
        keyboard="false">
        <Modal.Header>
          <Modal.Title>
            {orderError
              ? t('PAGE3_MODAL_ORDER_TITLE_ERROR')
              : t('PAGE3_MODAL_ORDER_TITLE')
            }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div id="service-spinner-wrapper mt-5" className="service-spinner-wrapper">
            {orderError ?
              t('PAGE3_MODAL_ORDER_ERROR_' + orderError)
              :
              <Spinner id="serviceSpinner" animation="border" role="status" className="service-spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }
          </div>
        </Modal.Body>
      </Modal>

      {espagoKey
        ? <Espago espagoKey={espagoKey.key} closeHandle={espagoHandle} />
        : null
      }

      {!bank
        ? null
        :
        <Modal
          size="lg"
          show={bank}
          backdrop="static"
          keyboard="false">
          <Modal.Header>
            <Modal.Title>{t('MODAL_BANK_TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="service-spinner-wrapper mt-5" className="service-spinner-wrapper bank-container">
              <div className="bank-wrapper">
                <p>{t('COMPANY')}</p>
                <p>{t('ADDRESS')}</p>
                <p>{t('POSTAL_CODE')}</p>
                <p>{t('CITY')}</p>
                <p>{t('IBAN')}</p>
                <p>{t('SWIFT')}</p>
                <p>{t('BANK_NAME')}</p>
                <p>{t('AMMOUNT')}</p>
                <p>{t('TITLE')}</p>
              </div>
              <div className="bank-wrapper highlight">
                <p>{partner.name}</p>
                <p>{partner.adress}</p>
                <p>{partner.postalCode}</p>
                <p>{partner.city}</p>
                <p> {bank.Iban}</p>
                <p> {bank.Swift}</p>
                <p> {bank.BankName}</p>
                <p> {bank.GrossPrice}{bank.Symbol}</p>
                <p> {bank.Id}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </div>
  );
}
export default withTranslation()(CustomerData);