import React, { Fragment } from "react";
import { Popup } from 'semantic-ui-react'
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "../../../store/actions/actionsCreators";

const CompanyInformation = ({ t, validateInput }) => {
  const { countries } = useSelector(state => state.configuration);
  const { company } = useSelector(state => state.customer);
  const dispatch = useDispatch();

  const changeHandle = (event) => {
    validateInput(event, 'company');
    const { name, value } = event.target;
    const data = {
      ...company,
      [name]: value
    };
    dispatch(setCompany(data));
  }

  const countriesOptions = countries
    ? countries.map((country) => {
      return (
        <option value={country.iso2} key={country.id}>{country.name}</option>
      );
    })
    : [];

  const inputsWithPopups = () => (
    <Fragment>
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="companyName"
            className="input-form"
            type="text"
            name="name"
            placeholder={t('PAGE2_COMPANY_NAME')}
            onChange={changeHandle}
            value={company.name}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="companyNameRequired">{t('COMPANY_NAME_REQUIRED')}</p>
          <p className="error-message hidden" id="companyNameMin">{t('COMPANY_NAME_MIN')}</p>
        </div>
        }
        content={t('PAGE2_COMPANY_NAME_C')}
        on='focus'
        offset='0, 50px'
        position='right center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="companyAddress"
            className="input-form"
            type="text"
            name="address"
            placeholder={t('PAGE2_COMPANY_ADDRESS')}
            onChange={changeHandle}
            value={company.address}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="companyAddressRequired">{t('COMPANY_ADDRESS_REQUIRED')}</p>
          <p className="error-message hidden" id="companyAddressMin">{t('COMPANY_ADDRESS_MIN')}</p>
        </div>}
        content={t('PAGE2_COMPANY_ADDRESS_C')}
        on='focus'
        offset='0, 50px'
        position='right center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="companyCity"
            className="input-form"
            type="text"
            name="city"
            placeholder={t('PAGE2_COMPANY_CITY')}
            onChange={changeHandle}
            value={company.city}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="companyCityRequired">{t('COMPANY_CITY_REQUIRED')}</p>
          <p className="error-message hidden" id="companyCityMin">{t('COMPANY_CITY_MIN')}</p>
        </div>}
        content={t('PAGE2_COMPANY_CITY_C')}
        on='focus'
        offset='0, 50px'
        position='right center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="companyPostCode"
            className="input-form"
            type="text"
            name="postCode"
            placeholder={t('PAGE2_COMPANY_POSTAL')}
            onChange={changeHandle}
            value={company.postCode}
            minLength="3"
            required
          />
          <p className="error-message hidden" id="companyPostCodeRequired">{t('COMPANY_POSTAL_REQUIRED')}</p>
          <p className="error-message hidden" id="companyPostCodeMin">{t('COMPANY_POSTAL_MIN')}</p>
        </div>}
        content={t('PAGE2_COMPANY_POSTAL_C')}
        on='focus'
        offset='0, 50px'
        position='right center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <select
            id="companyCountry"
            className="input-form"
            name="country"
            onChange={changeHandle}
            value={company.country}
            placeholder={'select country'}
          >
            {countriesOptions}
          </select>
        </div>
        }
        content={t('PAGE2_COMPANY_COUNTRY_C')}
        on='focus'
        position='right center'
      />
      <Popup
        trigger={<div className="mt-3 mb-5">
          <input
            id="companyTaxId"
            className="input-form"
            type="text"
            name="taxId"
            placeholder={t('PAGE2_COMPANY_TAX')}
            onChange={changeHandle}
            value={company.taxId}
          />
        </div>}
        content={t('PAGE2_COMPANY_TAX_C')}
        on='focus'
        offset='0, 50px'
        position='right center'
      />
    </Fragment>
  )

  return (
    <div className="ui form col-md-6 col-lg-4 mt-5 p-5 form-container">
      <label className="form-title mb-5">
        <i className="fas fa-briefcase" /> {t('PAGE2_COMPANY_INFORMATION')}
      </label>
      <div className="explanation-container">
        <span>
          <i className="fas fa-info-circle" />
          {t('PAGE2_COMPANY_INFORMATION2')}
        </span>
      </div>
      {inputsWithPopups()}
    </div>
  );
}

export default withTranslation()(CompanyInformation)
