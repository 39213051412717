import React, { useEffect, useState } from "react";
import { Icon } from 'semantic-ui-react';
import { withTranslation } from "react-i18next";

/* Styles */
import "./Agreements.css";
import { useDispatch, useSelector } from "react-redux";
import { setAgreements } from "../../../store/actions/actionsCreators";
import axios from "axios";

const Agreements = ({ t }) => {
  const dispatch = useDispatch();

  const { partner } = useSelector(state => state.partner);
  const links = useSelector(state => state.links);
  const agreements = useSelector(state => state.agreements);
  const { email } = useSelector(state => state.customer.contact);
  const [hiddenSection, setHiddenSection] = useState([false, false]);
  const [alreadyAgreed, setAlreadyAgreed] = useState([false, false]);

  if (!partner) {
    return null;
  }

  useEffect(() => {
    if (email) {
      axios.get(`shop/agreements/${email}`)
        .then(response => {
          const newAlreadyAgreed = [
            response.data.some(item => item.type === 3 && item.status === 1),
            response.data.some(item => item.type === 4 && item.status === 1),
          ];
          setAlreadyAgreed(newAlreadyAgreed);
        });
    }
  }, [email]);

  useEffect(() => {
  }, [agreements]);

  const changeHandle = (index) => {
    let newAgreements = [...agreements];
    const newValue = !newAgreements[index] | 0; //reverse current value and convert from bool to number
    
    newAgreements[index] = newValue;
    dispatch(setAgreements(newAgreements));
  }

  const checkAll = () => {
    const newAgreements = [...agreements];
    const newAgreementsChecked = newAgreements.map(agr => agr = 1);
    dispatch(setAgreements(newAgreementsChecked));
  }

  const changeHiddenSection = (index) => {
    let newSection = [...hiddenSection];
    newSection[index] = !newSection[index];
    setHiddenSection(newSection);
  }

  return (
    <div id="agreements" className="d-flex justify-content-end col-12 mb-5">
      <div className="col-12 col-lg-7">
        <label className="agreements-container" onClick={checkAll}>
          <Icon name='pencil alternate' />
          {t('AGREEMENTS_CHECK_ALL')}
        </label>

        {/* rules of sale  */}
        <label className="agreements-container">
          {t('AGREEMENTS_1')} <a href={links.rulesOfSale} target="_blank" rel="noopener noreferrer"> {t('AGREEMENTS_1_HREF')}</a>
          <input type="checkbox" checked={agreements[1]} name="isAgreed" id="isAgreed" />
          <span id="tick-policy" className="tick" onClick={() => changeHandle(1)}></span>
        </label>

        {/* privacy policy */}
        <label className="agreements-container">
          {t('AGREEMENTS_2')}
          <a href={links.privacyPolicy} target="_blank" rel="noopener noreferrer"> {t('AGREEMENTS_2_HREF')}</a>
          <input type="checkbox" checked={agreements[2]} name="isAgreed" id="isAgreed" />
          <span id="tick-policy" className="tick" onClick={() => changeHandle(2)}></span>
        </label>

        {/* entrusting data policy */}
        <label className="agreements-container">
          {t('AGREEMENTS_5')}
          <a href={links.entrustingDataPolicy} target="_blank" rel="noopener noreferrer"> {t('AGREEMENTS_5_HREF')}</a>
          <input type="checkbox" checked={agreements[5]} name="isAgreed" id="isAgreed" />
          <span id="tick-policy" className="tick" onClick={() => changeHandle(5)}></span>
        </label>

        {/* email marketing */}
        {alreadyAgreed[0]
          ? null
          : <label className="agreements-container">
            {t('AGREEMENTS_3')} <a className="read-more-text" onClick={() => changeHiddenSection(0)}>{t('AGREEMENTS_MORE')}</a>
            <p className={"read-more-paragraph " + (hiddenSection[0] ? " " : "read-more-hidden")}>
              {t('AGREEMENTS_3_1')}
              <a href={links.privacyPolicy} target="_blank"> {t('AGREEMENTS_MORE1')}</a>
            </p>
            <input type="checkbox" checked={agreements[3]} />
            <span className="tick" onClick={() => changeHandle(3)} />
          </label>
        }

        {/* phone marketing */}
        {alreadyAgreed[1]
          ? null
          : <label className="agreements-container">
            {t('AGREEMENTS_4')} <a className="read-more-text" onClick={() => changeHiddenSection(1)}>{t('AGREEMENTS_MORE')}</a>
            <p className={"read-more-paragraph " + (hiddenSection[1] ? " " : "read-more-hidden")}>
              {t('AGREEMENTS_4_1')}
              <a href={links.privacyPolicy} target="_blank"> {t('AGREEMENTS_MORE1')}</a>
            </p>
            <input type="checkbox" checked={agreements[4]} />
            <span className="tick" onClick={() => changeHandle(4)} />
          </label>
        }

        <p className="mt-4 mb-4">
          {t('PAGE2_AGR_7')} „{partner.name}” {t('PAGE2_AGR_8')}
          <a className="more-information" href={links.termsLink} target="_blank" rel="noopener noreferrer"> {t('PAGE2_AGR_9')}</a>
        </p>
      </div>
    </div>
  );
}

export default withTranslation()(Agreements)