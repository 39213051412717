export const SET_PAYMENT_FORM = "SET_PAYMENT_FORM";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
export const SET_SOFTWARE = "SET_SOFTWARE";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_VERSION = "SET_VERSION";
export const SET_ROOMS = "SET_ROOMS";
export const SET_ORDER_INFO = "SET_ORDER_INFO";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_HOTEL = "SET_HOTEL";
export const SET_COMPANY = "SET_COMPANY";
export const SET_CONTACT = "SET_CONTACT";
export const COPPY_HOTEL = "COPPY_HOTEL";
export const SET_PARTNER = "SET_PARTNER";
export const SET_DEALER = "SET_DEALER";
export const SET_LINKS = "SET_LINKS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_DURATION = "SET_DURATION";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_AGREEMENTS = "SET_AGREEMENTS";