import "./Rooms.css";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setRooms } from "../../../store/actions/actionsCreators";

const Rooms = ({ t }) => {
    const { rooms } = useSelector(state => state.configuration);
    const { property, roomId } = useSelector(state => state.orderInfo);
    const [campingQuestionControl, setCampingQuestionControl] = useState(null);
    const [value, setValue] = useState();
    const dispatch = useDispatch();

    const determineHeader = () => {
        let header = "";
        switch (property) {
            case "hotel":
                header = t('PAGE1_HOTEL_HANDLER');
                break;

            case "apartment":
                header = t('PAGE1_HOTEL_HANDLER1');
                break;

            case "hostel":
                header = t('PAGE1_HOTEL_HANDLER2');
                break;

            case "camping":
                header = t('PAGE1_CAMPING_BEDS');
                break;

            default:
                break;
        }
        return header;
    }

    const prepareSelect = () => {
        let options = rooms.map(m =>
            <option key={m.id} value={m.id}>{m.name}</option>
        );

        if (roomId === '' || !roomId) {
            let emptyOption = <option key='empty' value='' name='emptyR'>
                {t('ROOM_EMPTY')}
            </option>
            options.unshift(emptyOption);
        }
        const input =
            <select
                className="ui dropdown mt-5"
                onChange={(e) => setValue(e.target.value)}
                value={value}
            >
                {options}
            </select>

        return input;
    }

    useEffect(() => {
        if (property === "apartment") {
            setValue(0);
        } else if(roomId) {
            setValue(roomId);
        }else{
            setValue('');
        }

    }, [property]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log('value: ', value)
            let roomValue, roomId;
            if (property === "apartment" && rooms) {
                let roomItem;
                if (value <= 0)
                    roomItem = rooms.find(room => room.roomsCount === 10);
                else if (value > 300)
                    roomItem = rooms.find(room => room.roomsCount === 300);
                else if (value > 200 && value <= 300)
                    roomItem = rooms.find(room => room.roomsCount === 200);
                else if (value > 100 && value <= 200)
                    roomItem = rooms.find(room => room.roomsCount === 127);
                else
                    roomItem = rooms.find(room => value <= room.roomsCount);
                roomValue = value;
                roomId = roomItem.id;
            } else {
                roomValue = value;
                roomId = value;
            }
            dispatch(setRooms(roomId));
          }, 500);
      
          return () => clearTimeout(delayDebounceFn)
    }, [value, rooms]);

    const prepareInput = () => {
        const input =
            <input
                className="ui dropdown apartment-input mt-5"
                min="1"
                type="number"
                value={value}
                required
                onChange={(e) => setValue(e.target.value)}
                step={1}
            />;

        return input;
    }

    const prepareCampingQuestionaire = () => {
        const hideContainer = campingQuestionControl ? "" : " hidden-container";
        const options = rooms ? rooms.map(items =>
            <option key={items.id} value={items.id}>{items.name}</option>
        ) : null;

        return (
            <div className="camping-control">
                <div className="offers">
                    <div>
                        <label className="question">
                            {t('PAGE1_CAMPING_QUESTION')}
                        </label>

                        {/* Customizable radio buttons */}
                        <div className="container">
                            <label className="input">
                                <input type="radio" name="gender" onClick={() => setCampingQuestionControl(true)} />
                                {t('PAGE1_CAMPING_QUESTION_YES')}
                                <span className="checkmark" />
                            </label>

                            <label className="input">
                                <input type="radio" name="gender" onClick={() => setCampingQuestionControl(false)} />
                                {t('PAGE1_CAMPING_QUESTION_NO')}
                                <span className="checkmark" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className={"items-container" + hideContainer}>
                    <label className="question">{t('PAGE1_BEDS_AMMOUNT')}</label>
                    <select
                        name={'beds-select'}
                        className="amount"
                        onChange={(e) => setValue(e.target.value)}
                        defaultValue={roomId}
                        id="campingSelect"
                    >
                        <option>-</option>
                        {options}
                    </select>
                </div>
            </div>
        );
    }

    const determineInput = () => {
        let input;
        switch (property) {
            case "apartment":
                input = prepareInput();
                break;
            case "camping":
                input = prepareCampingQuestionaire();
                break;
            default:
                input = prepareSelect();

        }

        return input;
    }

    const header = determineHeader();
    const input = rooms ? determineInput() : null;

    return (
        <div className="ui form">
            <div>
                <label id="labelText" className="title">{header}</label>
                    {input}
            </div>
        </div>
    );
}

export default withTranslation()(Rooms)