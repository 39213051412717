import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { saveAs } from "file-saver";

import Spinner from "react-bootstrap/Spinner";
import Button from "../../GeneralComponents/Button/Button";

import "./PdfBtn.css"
import { useSelector } from "react-redux";
import { useActionLogger } from "../../../custom-hooks/useActionLogger";

const PdfBtn = ({ t }) => {
  const [loading, setLoading] = useState(0);
  const orderInfo = useSelector(state => state.orderInfo);
  const { partner } = useSelector(state => state.partner);
  const dealerId = useSelector(state => state.dealerId);
  const appLanguage = useSelector(state => state.appLanguage);
  const products = useSelector(state => state.products);
  const { code } = useSelector(state => state.discount);

  const logAction = useActionLogger();

  const partnerId = dealerId ? dealerId : partner.id;
  const data = [{id: orderInfo.softwareVersion}, ...products]

  const getPdfOffert = () => {
    setLoading(1)
    axios
      .post(
        "shop/pdf",
       data,
        {
          params: {
            languageGroupId: orderInfo.programLang,
            roomId: orderInfo.roomId,
            currencyId: orderInfo.currency,
            translation: appLanguage,
            partnerId: partnerId,
            resortType: orderInfo.property,
            duration: orderInfo.duration,
            discountCode: code
          },
          headers: {
            'Content-type': 'application/json'
          },
          responseType: 'blob'
        }
      )
      .then((response) => {
        var file = new Blob([response.data], { type: "text/plain;charset=utf-8" });
        saveAs(file, 'kwhotel_offert.pdf');
        setLoading(0);
        logAction(1, orderInfo.programLang);
      });
  };

  const spinner = (
    <div className="spinner-container">
      <Spinner
        id="serviceSpinner"
        animation="border"
        role="status"
        className="service-spinner pdf-spinner"
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>

  );

  const button = (
    <Button
      class="btn btn-prev"
      value={t("PAGE1_BTN_PDF")}
      click={getPdfOffert}
    >
    </Button>
  );

  const content = loading ? spinner : button;

  return (
    content
  );
}

export default withTranslation()(PdfBtn)