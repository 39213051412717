import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../Cart/Modal/Modal.css";
import "../LanguagesParser/LanguagesParser.css";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setVersion } from "../../../store/actions/actionsCreators";

const LanguagesParser = ({ t }) => {
  //skip rendering if not on production server
  if (process.env.NODE_ENV !== "production") return null;
  if (window.location.pathname.includes('summary')) return null;

  const {programLang} = useSelector(state => state.orderInfo);
  const dealerId = useSelector(state => state.dealerId);
  const { languages } = useSelector(state => state.configuration);

  console.log('programLang: ', programLang);

  const [language, setLanguage] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [url, setUrl] = useState();

  const dispatch = useDispatch();

  const urlMap = new Map();
  urlMap.set(1, "shop.kwhotel.pl");
  urlMap.set(43, "shop.kwhotel.pl");
  urlMap.set(44, "shop.kwhotel.pl");
  urlMap.set(2, "shop.kwhotel.com");

  useEffect(() => {
    if(!programLang || !languages){
      return
    }

    if (!language && programLang) {
      //on init setFallbackLangage as default site language
      const lng = window.location.href.indexOf('shop.kwhotel.com') > -1 ? 2 : 3;
      setLanguage(lng);
    }

    if (language && language === programLang) {
      return;
    }

    const lang = languages.find(lng => lng.id === programLang);
    const url = urlMap.get(lang.partnerId);

    //skip if we dont have an url
    if (typeof url === "undefined") return null;

    //do nothing if url matches current browser url or we are on development server
    const currentUrl = window.location.href;
    if (currentUrl.indexOf(url) > -1 || currentUrl.indexOf("dev") === 0) {
      return;
    }

    let redirectUrl = `https://${url}?lang=${lang.description}`;
    if (!urlMap.has(dealerId)) {
      redirectUrl = redirectUrl + `&partner=${dealerId}`;
    }

    setUrl(redirectUrl);
    setModalVisibility(true);
  }, [programLang, language, languages]);

  function redirectUrl() {
    window.location.href = url;
  }

  function closeModalWindow() {
    dispatch(setVersion(language));
    setModalVisibility(false);
  }

  return (
    <Modal id="modal" name="lang-modal" size="lg" backdrop="static" show={modalVisibility}>
      <Modal.Header>
        <div className="col-12">
          <div className="text-center mb-2 mt-2">
            <h2>{t("MODAL_REDIRECT_TITLE")}</h2>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 parser-dialog-body">
          {t("MODAL_REDIRECT_BODY")} {url}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {
          <div className="d-flex justify-content-end mt-5">
            <button className="btn mr-2" onClick={() => closeModalWindow()}>
              {t("MODAL_DECLINE")}
            </button>
            <button className="btn accept" onClick={() => redirectUrl()}>
              {t("MODAL_ACCEPT")}
            </button>
          </div>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default withTranslation()(LanguagesParser);
