import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";

import { Button } from "semantic-ui-react";
import DiscountCodeModal from "./DiscountCodeModal";

import "./DiscountCode.css";
import { setDiscount } from '../../../store/actions/actionsCreators';

const DiscountCode = ({ t, code = '', productsHandle }) => {
    const existingCode = useSelector(state => state.discount);
    const [discountCode, setDiscountCode] = useState(existingCode ? existingCode.code : '');
    const [discountError, setDiscountError] = useState(null);
    const [productIds, setProductIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
   

    useEffect(() => {
        if(existingCode && existingCode.code){
            setDiscountCode(existingCode.code);
        }
    }, [existingCode]);


    const dispatch = useDispatch();

    const onClickHandle = (event) => {
        if(discountCode.length === 0){
            setDiscountCode("");
            setProductIds([]);
            dispatch(setDiscount(""));
        }
        else {
            axios.get(`discounts/${discountCode}`)
            .then(response => {
                setDiscountCode(response.data.discount);
                setProductIds(response.data.productIds);

                if(productsHandle){
                    productsHandle(response.data.productIds);
                }
                setShowModal(true);
            }, response => {   
                setDiscountError(response.data);
                setShowModal(true);
            });
        }
    }

    const modalHandle = (value) => {
        if (value) {
            dispatch(setDiscount(discountCode));

            if(productsHandle){
                productsHandle(productIds)
            }
        }else {
            dispatch(setDiscount({}))
            setDiscountCode("");
            

            if(productsHandle){
                productsHandle([]);
            }
            
        }
        setShowModal(false);
    }

    return (
        <>
            <div className="discount-code-input-group">
                <input
                    value={discountCode}
                    placeholder={t('DISCOUNT_PLACEHOLDER')}
                    onChange={(event) => setDiscountCode(event.target.value)}
                    id="discount-code-id"
                    className="ui dropdown discount-code-input"
                />
                <Button
                    className="discount-code-btn"
                    onClick={onClickHandle}
                >
                    {t('DISCOUNT_BTN')}
                </Button>
            </div>
            <DiscountCodeModal
                discountError={discountError}
                productIds={productIds}
                discount={discountCode}
                visible={showModal}
                modalHandle={modalHandle} />
        </>
    )
}

export default withTranslation()(DiscountCode);