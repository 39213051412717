import { SET_CONTACT, SET_CUSTOMER, SET_HOTEL, SET_COMPANY, COPPY_HOTEL } from '../actions/constants';

const defaultState = {
    contact: {},
    company: { country: 'PL'},
    hotel: { country: 'PL'},
    default: true
}

export const customer = (state = defaultState, action) => {
    switch(action.type){
        case SET_CUSTOMER:{
            return action.payload
        }
        case SET_HOTEL:{
            return {
                ...state,
                hotel: action.payload
            }
        }
        case SET_COMPANY:{
            return {
                ...state,
                company: action.payload
            }
        }
        case SET_CONTACT:{
            return {
                ...state,
                contact: action.payload
            }
        }
        case COPPY_HOTEL:{
            return {
                ...state,
                hotel: {
                   name: state.company.name,
                   email: state.contact.email,
                   phone: state.contact.phoneNumber,
                   address: state.company.address,
                   city: state.company.city,
                   country: state.company.country,
                   postCode: state.company.postCode 
                }
            }
        }
        default: {
            return state;
        }
    }
}
