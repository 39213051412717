import React from "react";
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux';
import { setVersion } from "../../../store/actions/actionsCreators";

const LanguageVersion = ({ t }) => {
  const dispatch = useDispatch();
  const { languages } = useSelector(state => state.configuration);
  const { programLang } = useSelector(state => state.orderInfo);

  const handleChange = (event) => {
    const id = event.target.value;
    dispatch(setVersion(id));
  }

  if (!languages) {
    return null;
  }

  const optionsArray = languages.map(m =>
    <option key={m.id} value={m.id} name={m.name}>
      {t('P1_LANG_' + m.name)}
    </option>
  );

  return (
    <div className="ui form">
      <div className="field">
        <label className="title">{t("PAGE1_LANGUAGE")}</label>
        <select
          value={programLang}
          className="ui dropdown  mt-5 mb-5"
          onChange={handleChange}
          id="languageVersionSelect">
          {optionsArray}
        </select>
      </div>
    </div>
  );
}

export default withTranslation()(LanguageVersion);