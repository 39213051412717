import React from "react";
import "./Service.css";
import { withTranslation } from "react-i18next";

const Service = ({ id, program, t, addProduct, discount, discountProducts = [], discountExclusions = [], softwareVersion, selectedModules = [] }) => {
    const textIds = [154]; //modules with id in this array will have additional individual text displayed
    const replacementTextIds = [206];

    const checked = program.id === softwareVersion || selectedModules.map(prod => prod.id).includes(program.id);
    const disabled = program.id === softwareVersion;

    const showDiscount = !discountExclusions.includes(program.id) && !program.ipo && (discount.code && (discountProducts.find(el => el === program.id) || discountProducts.length === 0))
        ? true
        : false;

    const getProductPriceText = (product, currency) => {
        if (product.p + product.psf.oneTimePrice > 0) {
            if (product.ipo) {
                return `${product.psf.oneTimePrice} ${currency}`;
            } else {
                return `${product.p} ${currency}`;
            }
        } else {
            if (product.ev) {
                return t('translation:PAGE1_SERVICE_EVALUATION')
            }
            else {
                return t('translation:PAGE1_SERVICE_FREE')
            }
        }
    };

    const getSubText = (product, categoryId, currency) => {
        if(product.ev && product.p > 0){
            return t('translation:PAGE1_SERVICE_EVALUATION')
        }
        else if (product.ipo) {
            return t('translation:PAGE1_SERVICE_OTP');
        }  else if (!product.ipo && product.psf.oneTimePrice > 0) {
            if(replacementTextIds.includes(product.id)){
                return `${t('translation:PAGE1_INDIVIDUAL' + product.id)} ${product.psf.oneTimePrice} ${currency}`;
            }else{
                return `${t('translation:PAGE1_SERVICE_OTP' + categoryId)} ${product.psf.oneTimePrice} ${currency}`;
            }
        } else if (product.psf.setupPrice > 0) {
            return `${t('translation:PAGE1_SERVICE_OTP' + categoryId)} ${product.psf.setupPrice} ${currency}`;
        } else {
            return null;
        }
    }

    return (
        <div
            id={program.id}
            className="cards wow zoomIn">
            {/* Discount container*/}
            <div className="col-12 p-0">
                <div className="discount-tag">
                    {showDiscount && discount.value > 0
                        ?
                        <div className="discount">
                            <div className="discount-text">
                                -{discount.value * 100}%
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            {/* Input checkbox */}
            <label className="container col-1">
                <input
                    id={program.id}
                    type="checkbox"
                    name={program.name}
                    onChange={(e) => addProduct(e, checked)}
                    checked={checked}
                    disabled={disabled}
                />
                <span id={"check" + program.id} className="checkmark" />
            </label>

            {/* Card container */}
            <div className="card-content">
                <div className="wrapper-header col-12 row">
                    <div className="col-6 img-container">
                        <img className="module-icon" src={program.img} alt={program.name} />
                    </div>
                    <div className="col-6 price">
                        <h3 className="final-price">
                            {getProductPriceText(program, program.c)}
                        </h3>
                    </div>
                </div>
                <div className="col-12 additional-price row">
                    <p>
                        {getSubText(program, id, program.c)}
                    </p>
                </div>
                <div className="col-12 additional-price-individual row">
                    {/* applies individual text to modules in array */}
                    {textIds.includes(program.id) ?
                        <p>
                            {t('translation:PAGE1_INDIVIDUAL' + program.id)}
                        </p>
                        :
                        <div className="separator"></div>
                    }
                </div>
                <div className="col-12">
                    <h4>
                        {t(`products:${program.id}.Item1`)}
                    </h4>
                    <p className="desc-font">
                        {t(`products:${program.id}.Item2`)}
                    </p>
                </div>
            </div>
        </div>
    );
}
export default withTranslation(['translation', 'products'])(Service)