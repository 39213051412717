import { SET_CONFIGURATION } from '../actions/constants';

export const configuration = (state = {}, action) => {
    if(action.type === SET_CONFIGURATION){
        return {
            ...action.payload,
            loaded: true
        }
    }

    return state;
}