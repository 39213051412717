/* eslint-disable */
import React, { useState, useEffect } from "react";
import Slider from "react-rangeslider";
import { useDispatch, useSelector } from "react-redux";
import { setDuration } from "../../../store/actions/actionsCreators";

/* Styles */
import "./RangeSlider.css";

const DurationSlider = ({discount1, discount2}) => {
  const { duration } = useSelector(state => state.orderInfo);
  const [sliderValue, setSliderValue] = useState(duration);
  const dispatch = useDispatch();

  const generateLabels = () => {
    let horizontalLabels = {
      12: formatDiscountLabel(discount1),
    };

    if (discount1 !== discount2)
      horizontalLabels[24] = formatDiscountLabel(discount2);

    return horizontalLabels;
  };
 
  const formatDiscountLabel = (discount) => {
    return "-" + discount * 100 + "%";
  };
  
  const horizontalLabels = generateLabels();

  return (
    <div className="slider">
      <Slider
        min={1}
        max={36}
        value={sliderValue}
        labels={horizontalLabels}
        onChange={setSliderValue}
        onChangeComplete={() => dispatch(setDuration(sliderValue))}
      />
    </div>
  );
}

export default DurationSlider;
