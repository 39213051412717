import React from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import "./Property.js";

/* Styles */
import "./Resort.css";
import Property from "./Property.js";
import { setProperty } from "../../../store/actions/actionsCreators.js";

const Resort = ({ t }) => {
  const dispatch = useDispatch();
  const { property } = useSelector(state => state.orderInfo);

  const changeRadioValue = (e) => {
    const value = e.target.value;
    dispatch(setProperty(value));
  }

  return (
    <div className="ui form">
      <div id="object" className="field">
        <label className="title">{t('PAGE1_OBJECT_TYPE')}</label>
        <div className="resorts">
          {/* Hotel */}
          <Property name="hotel" selectedInput={property} changeRadioValue={changeRadioValue} />
          {/* Apartment */}
          <Property name="apartment" selectedInput={property} changeRadioValue={changeRadioValue} />
          {/* Hostel */}
          <Property name="hostel" selectedInput={property} changeRadioValue={changeRadioValue} />
          {/* Camping */}
          <Property name="camping" selectedInput={property} changeRadioValue={changeRadioValue} />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Resort)
