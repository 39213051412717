import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { setCustomer, setOrderInfo, setProducts } from '../store/actions/actionsCreators';

const cookies = new Cookies();

export const useAppCache = () => {
    const dispatch = useDispatch();
    const {orderInfo, products, customer} = useSelector(state => state);

    useEffect(() => {
        //initialize items from cache
        const orderInfoCookies = cookies.get('kw-order');
        const productsCookies = cookies.get('kw-products');
        const customerCookies = cookies.get('kw-customer');

        if(orderInfoCookies && orderInfoCookies.property && orderInfoCookies.softwareVersion && orderInfoCookies.programLang && orderInfoCookies.roomId){
            dispatch(setOrderInfo({...orderInfoCookies, preloaded: true}));
        }

        if(productsCookies){
            dispatch(setProducts(productsCookies))
        }

        if(customerCookies){
            dispatch(setCustomer(customerCookies))
        }

    }, [])

    useEffect(() => {
        cookies.set('kw-order', orderInfo, { maxAge: 1800});
    }, [orderInfo])

    useEffect(() => {
        cookies.set('kw-products', products, { maxAge: 1800});
    }, [products])

    useEffect(() => {
        cookies.set('kw-customer', customer, { maxAge: 1800});
    }, [customer])

    return;
}