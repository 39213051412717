import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BillingData = ({ t }) => {
  const { company } = useSelector(state => state.customer);

  return (
    <div className="col-12 col-lg-5 data wow bounceInRight">

      {/* It skews the parent div */}
      <div className="skew" />

      {/* Seller data content */}
      <div className="col-10 seller-data">
        <label className="form-title pt-5 pl-4">
          <i className="fas fa-receipt" /> {t('PAGE3_BILLING_TITLE')}
        </label>

        <table className="table table-hover mt-5 data-table">
          <tbody>
            <tr>
              <td className="title-header">{t('PAGE3_BILLING_NAME')}</td>
              <td>{company.name}</td>
            </tr>
            <tr>
              <td className="title-header">{t('PAGE3_BILLING_TAX')}</td>
              <td>{company.taxId}</td>
            </tr>
            <tr>
              <td className="title-header">{t('PAGE3_BILLING_ADDRESS')}</td>
              <td>{company.address}</td>
            </tr>
            <tr>
              <td className="title-header">{t('PAGE3_BILLING_CITY')}</td>
              <td>{company.city}</td>
            </tr>
            <tr>
              <td className="title-header">{t('PAGE3_BILLING_POSTAL')}</td>
              <td>{company.postCode}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default withTranslation()(BillingData);