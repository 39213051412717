import React, { Fragment } from "react";
import { Popup } from 'semantic-ui-react';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setContact } from "../../../store/actions/actionsCreators";

const ContactInformation = ({ t, validateInput }) => {
    const { contact } = useSelector(state => state.customer);
    const dispatch = useDispatch();

    const changeHandle = (event) => {
      validateInput(event, 'contact');
      const {name, value} = event.target;
      const data = {
        ...contact,
        [name]: value
      };
      dispatch(setContact(data));
    }


    const inputsWithPopups = () => (
      <Fragment>
        <Popup
          trigger={<div className="mt-3 mb-5">
            <input id="contactName"
              className="input-form"
              type="text"
              name="name"
              placeholder={t('PAGE2_CONTACT_NAME')}
              onChange={changeHandle}
              value={contact.name}
              minLength="3"
              required/>
            <p className="error-message hidden" id="contactNameRequired">{t('CONTACT_NAME_REQUIRED')}</p>
            <p className="error-message hidden" id="contactNameMin">{t('CONTACT_NAME_MIN')}</p>
          </div>}
          content={t('PAGE2_CONTACT_NAME_C')}
          on='focus'
          offset='0, 50px'
          position='right center'
        />
        <Popup
          trigger={<div className="mt-3 mb-5"> 
            <input
              id="contactSurname"
              className="input-form"
              type="text"
              name="surname"
              placeholder={t('PAGE2_CONTACT_SURNAME')}
              onChange={changeHandle}
              value={contact.surname}
              minLength="3"
              required/>
            <p className="error-message hidden" id="contactSurnameRequired">{t('CONTACT_SURNAME_REQUIRED')}</p>
            <p className="error-message hidden" id="contactSurnameMin">{t('CONTACT_SURNAME_MIN')}</p>
            </div>
           }
          content={t('PAGE2_CONTACT_SURNAME_C')}
          on='focus'
          offset='0, 50px'
          position='right center'
        />
        <Popup
          trigger={<div className="mt-3 mb-5"> 
          <input
            id="contactEmail"
            className="input-form"
            type="email"
            name="email"
            placeholder={t('PAGE2_CONTACT_EMAIL')}
            onChange={changeHandle}
            value={contact.email}
            minLength="3"
            required/>
            <p className="error-message hidden" id="contactEmailRequired">{t('CONTACT_EMAIL_REQUIRED')}</p>
            <p className="error-message hidden" id="contactEmailMin">{t('CONTACT_EMAIL_MIN')}</p>
            <p className="error-message hidden" id="contactEmailType">{t('CONTACT_EMAIL_TYPE')}</p>
          </div>}
          content={t('PAGE2_CONTACT_EMAIL_C')}
          on='focus'
          offset='0, 50px'
          position='right center'
        />
        <Popup
          trigger={<div className="mt-3 mb-5">
          <input
            id="contactPhoneNumber"
            className="input-form"
            type="text"
            name="phoneNumber"
            placeholder={t('PAGE2_CONTACT_PHONE')}
            onChange={changeHandle}
            value={contact.phoneNumber}
            minLength="3"
            required />
            <p className="error-message hidden" id="contactPhoneNumberRequired">{t('CONTACT_NUMBER_REQUIRED')}</p>
            <p className="error-message hidden" id="contactPhoneNumberMin">{t('CONTACT_NUMBER_MIN')}</p>
          </div>}
          content={t('PAGE2_CONTACT_PHONE_C')}
          on='focus'
          offset='0, 50px'
          position='right center'
        />
      </Fragment>
    )

    return (
      <div className="ui form col-md-6 col-lg-4 mt-5 p-5 form-container">
        <label className="form-title mb-5">
          <i className="fas fa-user-edit" /> {t('PAGE2_CONTACT_INFORMATION')}
        </label>

        <div className="explanation-container">
          <span>
            <i className="fas fa-info-circle" /> {t('PAGE2_CONTACT_INFORMATION2')}
          </span>
        </div>

        {inputsWithPopups()}

      </div>
    );
}

export default withTranslation()(ContactInformation)