import { SET_APP_LANGUAGE } from "../actions/constants";
import i18n from '../../config/i18n';

export const appLanguage = (state = null, action) => {
    if(action.type === SET_APP_LANGUAGE){
        const lang = action.payload.toLowerCase();
        console.log('store: ', lang);
        i18n.changeLanguage(lang);
        return lang;
    }

    return state;
}