import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

/* Styles */
import "./Modal.css";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux";

const Dialog = ({ t, id, click }) => {
  const globalLanguage = useSelector(state => state.appLanguage);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [data, setData] = useState(null);
  const [isSync, setIsSync] = useState(false);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (id) {
      getData(id, globalLanguage);
    }
  }, [id])

  const handleCbChange = (e) => {
    const isChecked = e.target.checked;
    const item = e.target;
    const newItems = new Map(checkedItems);
    newItems.set(item, isChecked);
    setCheckedItems(newItems);
  }

  const getData = (id, languageId) => {
    axios
      .get('locales/dialog/' + id + '?lang=' + languageId)
      .then(response => {
        setData(response.data);
        setIsSync(response.data.sync.length > 0);
      })
  }

  const BtnClickControl = (e, value) => {
    if (value) {
      const selectedProviders = [...checkedItems].filter(m => m[1])
        .map(m => { return { name: m[0].name, id: m[0].id } });
      click(e, value, selectedProviders);
    } else {
      click(e, value, []);
    }
    setData(null);
    setCheckedItems(new Map());
  }

  const generateSynchronizationRow = (checkboxList) => {
    if (!isSync) {
      return null;
    }

    //do not show whole window for additional language instalator
    if(id === 207){
      return (
        <div className="mt-5">
          <hr className="modal-line" />
          {checkboxList}
        </div>
      )
    }

    return (
      <div className="mt-5">
        <hr className="modal-line" />
        <h2 className="text-center mb-5">{t('MODAL_SYNC_HEADER_' + id)}</h2>
        <div className="mt-5 mb-5 modal-text" >
          {t('MODAL_SYNC_' + id)}
        </div>
        {checkboxList}
      </div>
    )
  }

  const modalVisibility = data ? true : false;

  const checkboxes = data
    ? data.sync.map(m =>
      <div className="col-12 mb-1">
        <label key={m.id}>
          <input type="checkbox" name={m.name} id={m.id} checked={checkedItems.get(m.id)} onChange={handleCbChange} />
          {m.name}
        </label>
      </div>)
    : null;

  const anyCheckedValue = ([...checkedItems.values()].some((value) => value));
  const acceptDisabled = checkboxes != null && checkboxes.length > 0 && !anyCheckedValue;

  return (
    <Modal
      id="modal"
      size="lg"
      backdrop="static"
      show={modalVisibility}
    >
      <Modal.Header closeButton>
        <div className="col-12">
          <h2 className="text-center mb-3">
            {data
              ? data.dialog.title
              : null
            }
          </h2>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          {data
            ?
            <div className="mt-5 modal-text"
              dangerouslySetInnerHTML={{ __html: data.dialog.text }}>
            </div>
            : null
          }
        </div>
        <div className="col-12">
          {generateSynchronizationRow(checkboxes)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {<div className="d-flex justify-content-end mt-5">
          <button className="btn mr-2" onClick={(e) => BtnClickControl(e, false)}>{t('MODAL_DECLINE')}</button>
          <button className="btn accept" onClick={(e) => BtnClickControl(e, true)} disabled={acceptDisabled}>{t('MODAL_ACCEPT')}</button>
        </div>}
      </Modal.Footer>
    </Modal>
  );
}
export default withTranslation()(Dialog);