import React from "react";
import { withTranslation } from "react-i18next";

import "./SellerData.css";
import { loadDealerInformation } from "./loadDealerInformation";
import { useSelector } from "react-redux";
const { useState, useEffect } = React;


const SellerData = ({ t }) => {
  const [dealer, setDealer] = useState(null);
  const { partner } = useSelector(state => state.partner);
  const dealerId = useSelector(state => state.dealerId);

  useEffect(() => {
    if (dealerId) {
      loadDealerInformation(dealerId).then((data) => {
        setDealer(data);
      });
    }
  }, [dealerId]);

  if (!partner) {
    return null;
  }

  return (
    <div className="col-12 col-lg-5 container data wow bounceInLeft">
      <div className="col-12 skew" />
      <div className="col-10 seller-data">
        <label className="form-title pt-5 pl-4">
          <i className="fas fa-user-tie" /> {t("PAGE3_SELLER_TITLE")}
        </label>
        <table className="table table-hover mt-5 data-table">
          <tbody>
            <tr>
              <td className="title-header">{t("PAGE3_SELLER_COMPANY")}</td>
              <td>{partner.name}</td>
            </tr>
            <tr>
              <td className="title-header">{t("PAGE3_SELLER_TAX")}</td>
              <td>{partner.taxId}</td>
            </tr>
            <tr>
              <td className="title-header">{t("PAGE3_SELLER_ADDRESS")}</td>
              <td>
                {partner.adress} {partner.city} {partner.postalCode}
              </td>
            </tr>
          </tbody>
          {dealer ? (
            <tbody>
              <tr>
                <td colSpan="1">{t("PAGE3_SELLER_DEALER")}</td>
              </tr>
              <tr>
                <td className="title-header">{t("PAGE3_SELLER_COMPANY")}</td>
                <td>{dealer.name}</td>
              </tr>
              <tr>
                <td className="title-header">{t("PAGE3_SELLER_TAX")}</td>
                <td>{dealer.taxId}</td>
              </tr>
              <tr>
                <td className="title-header">{t("PAGE3_SELLER_ADDRESS")}</td>
                <td>
                  {dealer.adress} {dealer.city} {dealer.postalCode}
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </div>
    </div>
  );
}

export default withTranslation()(SellerData);
