import {
    SET_CONFIGURATION, 
    SET_PROPERTY, 
    SET_ROOMS, 
    SET_SOFTWARE, 
    SET_VERSION, 
    SET_APP_LANGUAGE, 
    SET_ORDER_INFO,
    SET_CUSTOMER,
    SET_HOTEL,
    SET_COMPANY,
    SET_CONTACT,
    SET_PARTNER,
    SET_DEALER,
    SET_LINKS,
    SET_PRODUCTS,
    SET_PRODUCT,
    SET_DISCOUNT,
    SET_DURATION,
    SET_CURRENCY,
    COPPY_HOTEL,
    SET_PAYMENT_FORM,
    SET_AGREEMENTS
} from './constants';

export const setAgreements = agreements => {
    return {
        type: SET_AGREEMENTS,
        payload: agreements
    }
};

export const setPaymentForm = paymentForm => {
    return{
        type: SET_PAYMENT_FORM,
        payload: paymentForm
    }
}

export const coppyHotel = () => {
    return {
        type: COPPY_HOTEL,
        payload: null
    }
}

export const setCustomer = customer => {
    return {
        type: SET_CUSTOMER,
        payload: customer
    }
}

export const setCurrency = currency => {
    return {
        type: SET_CURRENCY,
        payload: currency
    }
}

export const setDuration = duration => {
    return {
        type: SET_DURATION,
        payload: duration
    }
}

export const setDiscount = discount => {
    return {
        type: SET_DISCOUNT,
        payload: discount
    }
}

export const setProduct = id => {
    return {
        type: SET_PRODUCT,
        payload: id
    }
}

export const setProducts = products => {
    return {
        type: SET_PRODUCTS,
        payload: products
    }
}

export const setLinks = links => {
    return {
        type: SET_LINKS,
        payload: links
    }
}

export const setDealer = id => {
    return {
        type: SET_DEALER,
        payload: id
    }
}

export const setPartner = partner => {
    return {
        type: SET_PARTNER,
        payload: partner
    }
}

export const setAppLanguage = language => {
    return {
        type: SET_APP_LANGUAGE,
        payload: language
    }
}

export const setContact = contact => {
    return {
        type: SET_CONTACT,
        payload: contact
    }
}



export const setHotel = hotel => {
    return {
        type: SET_HOTEL,
        payload: hotel
    }
}

export const setCompany = company => {
    return {
        type: SET_COMPANY,
        payload: company
    }
}

export const setConfiguration = configuration => {
    return {
        type: SET_CONFIGURATION,
        payload: configuration
    }
}

export const setOrderInfo = configuration => {
    return {
        type: SET_ORDER_INFO,
        payload: configuration
    }
}

export const setSoftware = id => {
    return {
        type: SET_SOFTWARE,
        payload: id
    }
}
export const setProperty = id => {
    return {
        type: SET_PROPERTY,
        payload: id
    }
}
export const setVersion = id => {
    return {
        type: SET_VERSION,
        payload: id
    }
}
export const setRooms = id => {
    return {
        type: SET_ROOMS,
        payload: id
    }
}