/* eslint-disable */
import React, { useEffect, useState, Fragment } from "react";
import WOW from "wowjs";
import "./Category.css";
import Service from "../Service/Service";
import { withTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import axios from "axios";

import { useSelector } from "react-redux";

const Category = ({ id, t, currency = {}, discountProducts, discountExclusions, productHandle }) => {
  const orderInfo = useSelector(state => state.orderInfo);
  const discount = useSelector(state => state.discount);
  const appLanguage = useSelector(state => state.appLanguage);
  const selectedProducts = useSelector(state => state.products);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const wow = new WOW.WOW({
      /* For solving mutationObserved and DOM mutation issues */
      live: false,
    });
    wow.init();
  }, []);

  useEffect(() => {
    if (orderInfo.programLang && orderInfo.softwareVersion && orderInfo.roomId && orderInfo.property) {
      loadCategoryData(id);
    }
  }, [orderInfo.programLang, orderInfo.softwareVersion, orderInfo.roomId, orderInfo.property]);

  const loadCategoryData = (categoryId) => {
    axios.get(`assortment/category/${categoryId}`,
      {
        params: {
          languageGroupId: orderInfo.programLang,
          programId: orderInfo.softwareVersion,
          roomId: orderInfo.roomId,
          property: orderInfo.property,
          translation: appLanguage
        }
      })
      .then(response => {
        setProducts(response.data);
      }, response => {
        console.error(`error loading category: ${categoryId}`);
      });
  }

  const manageProductClick = (e, checked) => {
    const itemId = parseInt(e.target.id);
    const item = products.find(i => i.id === itemId);
    if (productHandle)
      productHandle(item, checked);
  }

  const currencySymbol = currency.symbol ? currency.symbol : '';

  let cards = [];
  if (products) {
    cards = products.map((program) => (
      <Service
        id={id}
        key={program.id}
        program={program}
        addProduct={(e, checked) => manageProductClick(e, checked)}
        currency={currencySymbol}
        discount={discount}
        discountProducts={discountProducts}
        discountExclusions={discountExclusions}
        softwareVersion={orderInfo.softwareVersion}
        selectedModules={selectedProducts}
      />
    ));
  }

  const generateProductsComponent = () => {
    return <Fragment>
      <div id="modules" className="category-wrapper">
        <label className="title">{t(`categories:${id}`)}</label>
        <div className="cards-wrapper">
          {cards}
        </div>
      </div>
      {[13].includes(id) ? (
        <div className="container-fluid">
          <div className="row">
            <div className="asterisk-text">{t("PAGE1_ASTERISK")}</div>
          </div>
        </div>
      ) : null}
    </Fragment>
  }

  return (
    <>
      {products
        ? generateProductsComponent()
        : <Loader />
      }
    </>
  );
}

export default withTranslation()(Category);
