import { SET_LINKS } from "../actions/constants";

export const links = (state = {}, action) =>{
    switch (action.type){
        case SET_LINKS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}