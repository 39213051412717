import React, { useEffect, useState } from 'react';
import PaymentOption from './PaymentOption';

import './PaymentOptions.css'
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentForm } from '../../../store/actions/actionsCreators';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';

const PaymentOptions = ({ t }) => {
    const [paymentForms, setPaymentForms] = useState([]);

    const paymentForm = useSelector(state => state.paymentForm);
    const { partner } = useSelector(state => state.partner);
    const dealerId = useSelector(state => state.dealerId);
    const { currency } = useSelector(state => state.orderInfo);
    const dispatch = useDispatch();

    const paymentFormHandle = (key) => {
        dispatch(setPaymentForm(key));
    }

    useEffect(() => {
        if (currency && partner.id) {
            getPaymentOptions();
        }
    }, [currency, partner, dealerId]);

    const getPaymentOptions = () => {
        const partnerId = dealerId ? dealerId : partner.id;

        axios.get('shop/payments/' + partnerId, {
            params: {
                currencyId: currency
            }
        })
            .then(response => {
               setPaymentForms(response.data.filter(res => res.enabled))
            });
    }

    const paymentOptions = paymentForms.map(form => {
        return  <PaymentOption key={form.name} commision={form.commision} index={form.name} selectedIndex={paymentForm} clickHandle={(index) => paymentFormHandle(index)} />
    });


    return (
        <div className={"col-12 table-responsive bill-sumary wow bounceInUp payments-wrapper " + (paymentForms.length === 0 ? "hidden-scale" : "")}>
            <label className="col-12 form-title">
                <i className="fas fa-shopping-cart" /> {t('translation:PAGE3_PAYMENT')}
            </label>
            <div className="payment-options-container">
                {paymentOptions}
            </div>
        </div>
    )
}

export default withTranslation()(PaymentOptions);
