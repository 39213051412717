import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";

const defaultLang = window.location.href.indexOf('shop.kwhotel.com') > -1 ? "en" : "pl";
console.log('default: ', defaultLang);

i18n
    .use(XHR)
    .use(detector)
    .use(initReactI18next)
    .init({
        lng: defaultLang,
        react: {
            wait: true,
            useSuspense: false
        },
        saveMissing: process.env.NODE_ENV === 'production' ? true : false,
        ns: ["translation", "products", "categories", "providers"],
        defaultNS: "translation",
        backend: {
            loadPath: process.env.REACT_APP_API + 'locales/{{ns}}?lang={{lng}}',
            parse: function(data){
                return JSON.parse(data);
            },
            crossDomain: true,
            withCredentials: false,
            overrideMimeType: true
        }
    });

export default i18n;