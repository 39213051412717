import React from 'react';
import { withTranslation } from "react-i18next"
import { Modal } from "react-bootstrap";

import "./DiscountCodeModal.css";

const DiscountCodeModal = ({ t, visible, discount, productIds, modalHandle }) => {

    if (!visible) {
        return null;
    }

    const isValidDiscount = (discount && discount.value);

    const generateDiscountContent = () => {
        const products = productIds.map(id => generateProductCheckbox(id));

        return (
            <>
                <p className="discount-value-container">
                    {t('DISCOUNT_AMMOUNT')}
                    <span className="discount-value">{discount.value * 100}%</span>
                </p>
                <div className="col-12">
                    {productIds.length > 0 ? t('DISCOUNT_PRODUCTS_SELECTED') : t('DISCOUNT_PRODUCTS_ALL')}
                    {products}
                </div>
                <div className="col-12 discount-note">
                    {t('DISCOUNT_ADNOTATION')}
                </div>
            </>
        );
    }

    const generateBadDiscountCodeContent = () => {
        return "Kod rabatowy jest nieważny";
    }

    const generateProductCheckbox = (productId) => {
        return (
            <label key={productId} className="checkbox-container">
                <input key={productId} type="checkbox" checked={true}></input>
                {t(`products:${productId}.Item1`)}
            </label>
        )
    }
    const content = isValidDiscount ? generateDiscountContent() : generateBadDiscountCodeContent();

    return (
        <Modal
            id="modal"
            size="lg"
            backdrop="static"
            show={visible}
        >
            <Modal.Header closeButton>
                <div className="col-12">
                    <h2 className="text-center mb-3">
                        {isValidDiscount
                            ? t('DISCOUNT_AVAILABLE')
                            : t('DISCOUNT_EXPIRED')
                        }
                    </h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12">
                    {content}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end mt-5">
                    <button className="btn mr-2" onClick={() => modalHandle(false)}>{t('MODAL_DECLINE')}</button>
                    <button className="btn accept" onClick={() => modalHandle(true)} disabled={discount === null}>{t('MODAL_ACCEPT')}</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default withTranslation()(DiscountCodeModal)