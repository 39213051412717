import React from "react";
import { withTranslation } from "react-i18next";
import "./ProgramVersion.css";

import { useDispatch, useSelector } from 'react-redux';
import { setSoftware } from "../../../store/actions/actionsCreators";

const ProgramVersion = ({ t }) => {
  const dispatch = useDispatch();
  const orderInfo = useSelector(state => state.orderInfo);
  const { programs } = useSelector(state => state.configuration);

  const getBorderClass = () => {
    if (orderInfo.roomId && !orderInfo.softwareVersion) {
      return "selection-reminder"
    } else {
      return "";
    }
  }

  const changeHandle = (event) => {
    const id = event.target.value;
    dispatch(setSoftware(id));
  }

  const prepareSoftwareOption = (id, name, value) => {
    const translation = name.includes("Pro") ? t('P1_VER_PRO') : t('P1_VER_ST');
    const option =
      <option key={id} value={value} name={name}>
        {name} {translation}
      </option>

    return option;
  }

  const prepareEmptyOption = () => {
    return <option key='empty' value='' name='empty'>
      {t('PROGRAM_EMPTY')}
    </option>
  }

  if (!programs) {
    return null;
  }

  const border = getBorderClass();
  let options = programs.map(m => prepareSoftwareOption(m.id, m.name, m.id));

  if (orderInfo.softwareVersion === '' || !orderInfo.softwareVersion) {
    options.unshift(prepareEmptyOption());
  }

  return (
    <div className="ui form">
      <div className="field">
        <label className="title">{t('PAGE1_PROGRAM')}</label>
        <select
          className={"ui dropdown mb-5 mt-5 " + border}
          onChange={changeHandle}
          value={orderInfo.softwareVersion}
        >
          {options}
        </select>
      </div>
    </div>
  );

}

export default withTranslation()(ProgramVersion);
