import axios from "axios";

export const useActionLogger = () => {

    const logAction = (value, languageGroupId, country = "") => {
        axios.post(`logs/${value}?country=${country}&languageGroupId=${languageGroupId}`, response => {
        });
    }

    return logAction;
}