import React from "react";
import { withTranslation } from "react-i18next";
import bankLogo from "./img1/bank.png";
import visaLogo from "./img1/visa_pos.png";
import mcLogo from "./img1/mastercard_pos.png";
import payuLogo from "./img1/payu.png";
import paypalLogo from "./img1/pp-acceptance-medium.png";
import tpayLogo from "./img1/logo-tpay-50x25.svg";

/* Styles */
import "./Footer.css";
import { useSelector } from "react-redux";

const Footer = ({ t }) => {
  const links = useSelector(state => state.links);
  const {partner} = useSelector(state => state.partner);

  return (
    <div id="footer">
      <div className="footer-links">
        <p>
          {t('FOOTER_PHRASE1')}{" "}
          <a href={links.rulesOfSale} target="_blank" rel="noopener noreferrer">{t('FOOTER_TERMS_OF_SALE')}</a> {t('FOOTER_PHRASE2')}{" "}
          <a href={links.privacyPolicy} target="_blank" rel="noopener noreferrer">{t('FOOTER_PRIVACY_POLICY')}</a>
        </p>
        <p>
          {t('FOOTER_PHRASE3')} „{partner.name}”{" "}
          <a href={links.termsLink} target="_blank" rel="noopener noreferrer">{t('FOOTER_MORE_INFO')}</a>
        </p>
      </div>

      <div>
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={visaLogo}
          width="55"
          height="35"
          border="0"
          className="footer-img"
        />
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={mcLogo}
          width="55"
          height="35"
          border="0"
          className="footer-img"
        />
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={tpayLogo}
          width="70"
          height="35"
          border="0"
          className="footer-img"
        />
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={paypalLogo}
          width="54"
          height="35"
          border="0"
          className="footer-img"
        />
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={payuLogo}
          width="66"
          height="35"
          border="0"
          className="footer-img"
        />
        <img
          alt="Credit Card Logos"
          title="Credit Card Logos"
          src={bankLogo}
          width="93"
          height="35"
          border="0"
          className="footer-img"
        />
      </div>
    </div>
  );
}
export default withTranslation()(Footer);